/**
 * Deposit Handler
 *
 * Select the invested token from the current select pool,
 * deposit a given amount of it in the Curve pool,
 * then stake the LP token into Convex associated pool.
 *
 * @module Actions/web3/Deposit
 */

import React, { useState } from "react";

// WEB3
import Web3 from "web3";
import { AbiItem } from "web3-utils";

// LOCAL
import Contract from "../../../contracts/ConvexStrategist.json";
import CurveRegistry from "../../../contracts/ICurveRegistry.json";
import { CurveRegistry as CurveRegistryAddress } from "../../../constants/addresses";
import usePoolInfos from "../../../store/PoolInfos";
import { getDBItem, getReinvestedTokenByPool } from "../../../utils/tokenInfos";
import { getBalanceOf } from "../../../utils/helpers";
import { useWeb3React } from "@web3-react/core";
import { ERC20 } from "../../../constants/abi";
import { approveIfNecessary } from "../Approve";

// MATERIAL UI
import { DialogTitle } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import useStyles from "./styles";
import { floatToERC20, ERC20ToFloat } from "../../../utils/BN";
import usePopup from "../../Popup";

const validate = (entry: string, maxAmountERC20: string, decimals: number, onError: (errorMessage: string) => void) => {
  // Format Check
  const regex = new RegExp(/^\d*(.\d+)?$/);
  if (!regex.test(entry)) {
    onError("You can only write numbers (integer or floating point).");
    return false;
  }

  // if the decimal part has more digits than the maximum allowed decimals
  if ((regex.exec(entry)![1]?.length ?? 0) > decimals+1) {
    onError("The maximum number of decimals handled by this token is " + decimals + ".");
    return false;
  }
  const ERC20Entry = floatToERC20(entry, decimals);

  // NULL Balance Check
  if (ERC20Entry.eqn(0)) {
    onError("Current amount (" + entry + ") is too low.");
    return false;
  }

  // MAX Balance Check
  if (ERC20Entry.gt(Web3.utils.toBN(maxAmountERC20))) {
    onError("Amount too high. Your current balance is " + ERC20ToFloat(maxAmountERC20, decimals).toString() + ".");
    return false;
  }

  return true;
};

export const deposit = async (web3: Web3, account: string, rawAmount: string, poolIndex: number) => {

  const contractAddr = process.env.REACT_APP_ADDRESS!;
  const currentStrat = getDBItem(poolIndex);
  const instance = new web3.eth.Contract(Contract.abi as AbiItem[], contractAddr);
  const tokenDeposited = new web3.eth.Contract(ERC20, getReinvestedTokenByPool(poolIndex).addr);
  const power = parseInt(await tokenDeposited.methods.decimals().call());
  const amount = floatToERC20(rawAmount, power);
  await approveIfNecessary(web3, tokenDeposited.options.address, amount, contractAddr, account);
  const curvePooLAddress = currentStrat.curvePooL;
  const convexPooLAddress = currentStrat.convexPooL;
  const curveReg = new web3.eth.Contract(CurveRegistry.abi as AbiItem[], CurveRegistryAddress);
  const curveLPToken = await curveReg.methods.get_lp_token(curvePooLAddress).call();
  const [nCoins] = await curveReg.methods.get_n_coins(curvePooLAddress).call();
  const indexInAmounts = currentStrat.indexCoinToReinvestOn;
  const params = {
    amount: amount.toString(),
    nCoins: nCoins.toString(),
    indexInAmounts: indexInAmounts.toString(),
    inner: false,
    token: tokenDeposited.options.address,
    curvePooL: curvePooLAddress,
    convexPooL: convexPooLAddress,
    curveLPToken: curveLPToken,
  };
  console.dir(params, { depth: null });
  await instance.methods.depositAndStakeFor(params).send({ from: account });
};

export interface DepositProps {
  setStateLoader: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export const DepositDialog: React.FC<DepositProps> = (props) => {
  const classes = useStyles();
  const context = useWeb3React<Web3>();
  const { data: selectedPoolIndex } = usePoolInfos();
  const [openPopup] = usePopup();
  const [depositAmount, setDepositAmount] = useState<string>("");
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false);
  const [errorText, setErrorText] = useState("");

  const reinvestedToken = getReinvestedTokenByPool(selectedPoolIndex);
  const { setStateLoader, onClose } = props;

  const onClickDeposit = async () => {
    setStateLoader(true);
    try {
      await deposit(context.library!, context.account!, depositAmount, selectedPoolIndex);
      openPopup("Deposit Success!", "info");
    } catch (e) {
      openPopup("Error while depositing in Convex.", "error");
    }
    onClose();
    setStateLoader(false);
  };

  const handleChange = async (newValue: string) => {
    const maxAmount = await getBalanceOf(context.library!, context.account!, reinvestedToken.addr);
    const tokenDeposited = new context.library!.eth.Contract(ERC20, reinvestedToken.addr);
    const decimals = parseInt(await tokenDeposited.methods.decimals().call());
    const isValid = validate(newValue, maxAmount, decimals, setErrorText);
    setIsAmountValid(isValid);
    setDepositAmount(newValue);
  };

  const setMaxAmountDeposit = async () => {
    const tokenDeposited = new context.library!.eth.Contract(ERC20, reinvestedToken.addr);
    const decimals = parseInt(await tokenDeposited.methods.decimals().call());
    const ERC20MaxAmount = await getBalanceOf(context.library!, context.account!, reinvestedToken.addr);
    const floatMaxAmount = ERC20ToFloat(ERC20MaxAmount, decimals);
    const isValid = validate(floatMaxAmount, ERC20MaxAmount, decimals, setErrorText);
    setIsAmountValid(isValid);
    setDepositAmount(floatMaxAmount);
  };

  return (
    <>
      <DialogTitle className={classes.simpleDialogTitle}>Deposit Process</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.textDeposit}>
          You can deposit <b>{reinvestedToken.name}</b> here.
          The smart contract then deposits into Curve and stake into Convex. It stake the tokens as if you had staked them yourself, from Convex's perspective you are
          the true staker. At no times does the contract own your tokens.
        </DialogContentText>
        <DialogContentText className={classes.textDeposit}>
          Their is 2 transactions required for the deposit: approve the contract to spend your tokens, and deposit on Convex. <br />
          Approval transactions are only requested if required, and will always set the allowance to the exact deposited amount.
        </DialogContentText>
        <DialogContentText className={classes.textDeposit}>
          You can claim your rewards at any time in the future, either here with the <b>Harvest button</b>, or on the Convex website.
        </DialogContentText>
      </DialogContent>
      <Grid container className={classes.formSectionContainer}>
        <TextField
          value={depositAmount}
          label={reinvestedToken.name}
          variant="outlined"
          onChange={(event) => handleChange(event.target.value)}
          error={!isAmountValid && depositAmount !== ""}
          helperText={!isAmountValid && depositAmount !== "" && errorText}
          className={classes.formSection}
        />
        <Button size="small" onClick={setMaxAmountDeposit} color="primary" variant="contained" className={classes.buttonMax}>
          MAX
        </Button>
      </Grid>

      <DialogActions className={classes.modalActions}>
        <Button disabled={!isAmountValid || depositAmount === ""} size="large" onClick={onClickDeposit} color="primary" variant="contained">
          Deposit
        </Button>
      </DialogActions>
    </>
  );
};

export default DepositDialog;
