import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        simpleDialogTitle: {
            textAlign: "center",
        },
        textDeposit: {
            // textAlign: "center",
        },
        formSectionContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        formSection: {},
        buttonMax: {
            margin: theme.spacing(1),
        },
        textButtonMax: {},
        modalActions: {
            justifyContent: "center",
            margin: theme.spacing(1),
        },
        approveSection: {
            marginTop: theme.spacing(1),
            alignSelf: "center",
        },
        checkBoxSection: {
            alignSelf: "center",
        },
        approveButton: {
            margin: theme.spacing(1),
        },
        notNeededApproveInfoText: {
            fontStyle: "italic",
            textAlign: "center",
        },
    })
);
