/**
 * Defines the DEXes Selector, which allow the user to choose
 * its prefered or the optimal exchange before harvesting.
 *
 * @module Actions/Harvest/DEXSelector
 */

import { useState, useEffect } from "react";

// WEB3
import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import { OptimalRate } from "paraswap-core";
import { Transaction } from "paraswap";

// LOCAL
import { AllSwapRates, BestSwapRates, SwapRateData, createSwapeRateData, DEXes, RewardTokens } from "../../../interfaces/DEXes";
import { BN, toBN, ERC20ToFloat, setFloatPrecision, floatToERC20 } from "../../../utils/BN";
import tokenInfos from "../../../constants/tokenInfos";
import { ERC20 as ABIERC20 } from "../../../constants/abi";
import { oneInchSwaperApprove, AugustusSwaperV5 } from "../../../constants/addresses";
import { getParaswapTx, swapConfig, getOneInchDataTx } from "../../../utils/helpers";
import getPoolTokenInfos, { getDBItem, getReinvestedTokenByPool } from "../../../utils/tokenInfos";
import usePoolInfos from "../../../store/PoolInfos";
import useTestMode from "../../../store/TestMode";
import useHarvestData from "../../../store/HarvestData";
import hasOwnProperty from "../../../utils/typeUtils";

// MATERIAL UI
import { Button, Grid, CircularProgress, Fade, FormHelperText, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { min } from "bn.js";
import usePopup from "../../Popup";


// TokenInfo data
const crvInfo = tokenInfos.find(({ symbol }) => symbol === "CRV")!;
const cvxInfo = tokenInfos.find(({ symbol }) => symbol === "CVX")!;

const getAllSwapRates = async (web3: Web3, crvToHarvest: BN, cvxToHarvest: BN, tokenToAddr: string, contractAddr: string): Promise<AllSwapRates> => {
  const crv = new web3.eth.Contract(crvInfo.abi, crvInfo.address);
  const cvx = new web3.eth.Contract(cvxInfo.abi, cvxInfo.address);
  const crvDecimals = await crv.methods.decimals().call();
  const cvxDecimals = await cvx.methods.decimals().call();

  const tokenTo = new web3.eth.Contract(ABIERC20, tokenToAddr);
  const tokenToDecimals = await tokenTo.methods.decimals().call();
  const tokenToSymbol = await tokenTo.methods.symbol().call();

  // Get DEXes data
  const [PSCRVPriceRoute, PSCRVTxData] = await getParaswapTx(
    crv.options.address,
    crvDecimals,
    tokenTo.options.address,
    tokenToDecimals,
    crvToHarvest.toString(),
    "Reaper",
    contractAddr,
    swapConfig.rateOptionsParaswap,
    swapConfig.buildOptionsParaswap
  );
  const [PSCVXPriceRoute, PSCVXTxData] = await getParaswapTx(
    cvx.options.address,
    cvxDecimals,
    tokenTo.options.address,
    tokenToDecimals,
    cvxToHarvest.toString(),
    "Reaper",
    contractAddr,
    swapConfig.rateOptionsParaswap,
    swapConfig.buildOptionsParaswap
  );
  const [OICRVData, OICRVStatus] = await getOneInchDataTx(crv.options.address, tokenTo.options.address, crvToHarvest.toString(), contractAddr, swapConfig.maxSlippage);
  const [OICVXData, OICVXStatus] = await getOneInchDataTx(cvx.options.address, tokenTo.options.address, cvxToHarvest.toString(), contractAddr, swapConfig.maxSlippage);

  if ((hasOwnProperty(PSCRVPriceRoute, "message") && OICRVStatus !== 200) || (hasOwnProperty(PSCVXPriceRoute, "message") && OICVXStatus !== 200)) {
    // 2 or more API call failed
    throw new Error(
      "Error while calling DEXs APIs.\nPSCRVPriceRoute: " +
        JSON.stringify(PSCRVPriceRoute) +
        "\nPSCVXPriceRoute: " +
        JSON.stringify(PSCVXPriceRoute) +
        "\nOICRVData: " +
        JSON.stringify(OICRVData) +
        "\nOICVXData: " +
        JSON.stringify(OICVXData)
    );
  }

  const PSCRVAmount = (PSCRVPriceRoute as OptimalRate).destAmount;
  const PSCVXAmount = (PSCVXPriceRoute as OptimalRate).destAmount;
  const OICRVAmount = OICRVData.toTokenAmount;
  const OICVXAmount = OICVXData.toTokenAmount;

  console.log("Paraswap amount (CRV & CVX): " + PSCRVAmount + " & " + PSCVXAmount);
  console.log("1Inch amount (CRV & CVX): " + OICRVAmount + " & " + OICVXAmount);

  return {
    CRV: [
      createSwapeRateData("Paraswap", AugustusSwaperV5, "CRV", crvToHarvest.toString(), tokenToSymbol, PSCRVAmount, (PSCRVTxData as Transaction).data),
      createSwapeRateData("1inch", oneInchSwaperApprove, "CRV", crvToHarvest.toString(), tokenToSymbol, OICRVAmount, OICRVData.tx.data),
    ],
    CVX: [
      createSwapeRateData("Paraswap", AugustusSwaperV5, "CVX", cvxToHarvest.toString(), tokenToSymbol, PSCVXAmount, (PSCVXTxData as Transaction).data),
      createSwapeRateData("1inch", oneInchSwaperApprove, "CVX", cvxToHarvest.toString(), tokenToSymbol, OICVXAmount, OICVXData.tx.data),
    ],
  };
};

const chooseBestRates = (swapRates: AllSwapRates): [BestSwapRates, [number, number]] => {
  // construct an array that hold 1 SwapRateData for each DEX used.
  // One array per token (CRV + CVX).
  const CRVApiResults: SwapRateData[] = swapRates.CRV.filter((elem) => elem.amountOut !== undefined);
  const CVXApiResults: SwapRateData[] = swapRates.CVX.filter((elem) => elem.amountOut !== undefined);

  // check if every token has at least one good tx.
  if (CRVApiResults.length === 0 || CVXApiResults.length === 0) {
    throw new Error("Too many API calls fail while requesting Swap Data.");
  }

  // we then use a reducer to find the DEX with the maximum amount out.
  const reducer = (accumulator: SwapRateData, value: SwapRateData) => {
    // todo: add gas cost when comparing DEXs
    if (accumulator.amountOut <= value.amountOut) {
      return value;
    }
    return accumulator;
  };
  const result: BestSwapRates = [CRVApiResults.reduce(reducer), CVXApiResults.reduce(reducer)];
  return [result, [swapRates.CRV.indexOf(result[0]), swapRates.CVX.indexOf(result[1])]];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DEXSelectorRoot: {
      justifyContent: "center",
    },
    DEXSelectorContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    DEXSelectorContent: {
      textAlign: "center",
      alignSelf: "center",
    },
  })
);

export const DEXSelector: React.FC = () => {
  const classes = useStyles();
  const { library: web3, account } = useWeb3React<Web3>();
  const { data: selectedPoolIndex } = usePoolInfos();
  const currentPooL = getDBItem(selectedPoolIndex);
  const { data: harvestData, dispatch } = useHarvestData();
  const { data: isInTestMode } = useTestMode();
  const openPopup = usePopup();
  // todo: better accessor to convert a coinTab to its corresponding tokenInfo entry
  const destTokenInfo = getPoolTokenInfos(selectedPoolIndex, isInTestMode)[currentPooL.indexCoinToReinvestOn + (isInTestMode ? 2 : 1)]; //+x to skip ETH, WETH

  const defaultSwapRateData = createSwapeRateData("...", "addr", "CRV", "...","CVX", "...", "0x0");
  const [allSwapRates, setAllSwapRates] = useState<AllSwapRates>({
    CRV: [defaultSwapRateData, defaultSwapRateData],
    CVX: [defaultSwapRateData, defaultSwapRateData],
  });

  const [selectedSwapRates, setSelectedSwapRates] = useState<[number, number]>([0, 0]);
  const [swapRatesUpdater, setSwapRatesUpdater] = useState<boolean>(false);
  const [destToken, setDestToken] = useState<Contract>();
  const [destTokenDecimals, setDestTokenDecimals] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [failMsg, setFailMsg] = useState<string>("An Error occured. Please refresh the rates.");

  useEffect(() => {
    if (web3 !== undefined) {
      setDestToken(new web3.eth.Contract(destTokenInfo.abi, destTokenInfo.address));
    }
  }, [web3, destTokenInfo]);

  useEffect(() => {
    if (destToken !== undefined) {
      destToken.methods
        .decimals()
        .call()
        .then((decimals: string) => {
          setDestTokenDecimals(parseInt(decimals));
        });
    }
  }, [destToken]);

  useEffect(() => {
    setSwapRatesUpdater(!swapRatesUpdater);
  }, [harvestData.CRVAllowance, harvestData.CVXAllowance, harvestData.CRVToSwap, harvestData.CVXToSwap, harvestData.inWalletCRV, harvestData.inWalletCVX]);

  const getRates = async () => {
    dispatch({type: "swapData", payload: undefined});
    if (web3 === undefined || account === undefined) {
      return;
    }
    const contractAddr = process.env.REACT_APP_ADDRESS!;
    
    let crvToHarvest = toBN(harvestData.CRVToSwap).iadd(toBN(harvestData.inWalletCRV))
    let cvxToHarvest = toBN(harvestData.CVXToSwap).iadd(toBN(harvestData.inWalletCVX))

    // NB: allow to compute DEXes results without approval (since it is an estimation)
    // crvToHarvest = min(crvToHarvest, toBN(harvestData.CRVAllowance));
    // cvxToHarvest = min(cvxToHarvest, toBN(harvestData.CVXAllowance));

    const thresholdSwap = floatToERC20("1", 18);
    if (crvToHarvest.lt(thresholdSwap) || cvxToHarvest.lt(thresholdSwap)) {
      throw "insufficient available tokens. (" + setFloatPrecision(ERC20ToFloat(crvToHarvest, 18), 3) + " / 1 & " + setFloatPrecision(ERC20ToFloat(cvxToHarvest, 18), 3) + " / 1).";
    }

    const newAllSwapRates = await getAllSwapRates(web3, crvToHarvest, cvxToHarvest, getReinvestedTokenByPool(selectedPoolIndex).addr, contractAddr);
    setAllSwapRates(newAllSwapRates);
    const [bestSwapRates, selectedSwapRates] = chooseBestRates(newAllSwapRates);
    setSelectedSwapRates(selectedSwapRates);
    dispatch({type: "swapData", payload: bestSwapRates});
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      setAllSwapRates({
        CRV: [defaultSwapRateData, defaultSwapRateData],
        CVX: [defaultSwapRateData, defaultSwapRateData],
      });
      getRates().then(
        () => {
          // success
          setHasFailed(false);
          setFailMsg("");
          setIsLoading(false);
        },
        (reason: Error) => {
          // error
          console.log("Error while retrieving rates: " + reason);
          if (reason?.message) {
            // todo: parse web3js error messages.
            // openPopup(reason.message, "error")
          }
          setHasFailed(true);
          if (typeof reason === "string") {
            setFailMsg(reason);
          } else if (failMsg === "") {
            setFailMsg("An Error occured. Please refresh the rates.")
          }
          setIsLoading(false);
          //todo: may be useless
          dispatch({type: "swapData", payload: undefined});
        }
      );
    }
  }, [swapRatesUpdater]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, token: RewardTokens) => {
    const selectedDex: DEXes = (event.target as HTMLInputElement).value as DEXes;
    const correspondingData = allSwapRates[token].find((value) => value.dexName === selectedDex)!;
    const swapRateIndex = allSwapRates[token].indexOf(correspondingData);

    selectedSwapRates[token === "CRV" ? 0 : 1] = swapRateIndex;
    setSelectedSwapRates(selectedSwapRates);
    dispatch({type: "swapData", payload: [allSwapRates.CRV[selectedSwapRates[0]], allSwapRates.CVX[selectedSwapRates[1]]]});
  };

  const generateRadioLabel = (elem: SwapRateData) => {
    if (elem.amountOut === undefined || elem.rawTxData === undefined) {
      return elem.dexName + ": Error.";
    }
    else if (elem.amountOut === "...") {
      return "...";
    }

    // Apply fees on display ; It will be computed again later
    const bipsBaseValue = 10000;
    const feesApplied = toBN(elem.amountOut)
      .imuln(bipsBaseValue - swapConfig.feesInBips)
      .idivn(bipsBaseValue);
    const amount = setFloatPrecision(ERC20ToFloat(feesApplied, destTokenDecimals), 3);

    return elem.dexName + ": " + amount + " " + destTokenInfo.symbol;
  };

  return (
    <Grid container className={classes.DEXSelectorRoot}>
      <Grid item xs={10} style={{ display: "flex" }}>
        <Typography style={{ textAlign: "center" }}>You can choose which Decentralized Exchange plateform you want to use:</Typography>
        <Button
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            setSwapRatesUpdater(!swapRatesUpdater);
          }}
        >
          Refresh Rates
        </Button>
        <Fade in={isLoading}>
          <CircularProgress variant="indeterminate" style={{ display: "flex", marginLeft: 20, alignSelf: "center" }} />
        </Fade>
      </Grid>
      <Grid container item style={{ justifyContent: "space-evenly", marginTop: 8 }}>
        <Grid item component={FormControl} xs={5} style={{flexDirection: "column"}}>
          <FormLabel>CRV</FormLabel>
          <RadioGroup value={harvestData.swapData?.[0]?.dexName} onChange={(event) => handleChange(event, "CRV")}>
            {allSwapRates.CRV.map((elem) => {
              // may happens on API timeout, for example
              const isInvalid = (elem.amountOut === undefined || elem.rawTxData === undefined);
              return <FormControlLabel disabled={isInvalid || isLoading || hasFailed} value={elem.dexName} control={<Radio color="primary" />} label={generateRadioLabel(elem)} />;
            })}
          </RadioGroup>
        </Grid>
        <Grid item component={FormControl} xs={5} style={{flexDirection: "column"}}>
          <FormLabel>CVX</FormLabel>
          <RadioGroup value={harvestData.swapData?.[1]?.dexName} onChange={(event) => handleChange(event, "CVX")}>
            {allSwapRates.CVX.map((elem) => {
              // may happens on API timeout, for example
              const isInvalid = (elem.amountOut === undefined || elem.rawTxData === undefined);
              return <FormControlLabel disabled={isInvalid || isLoading || hasFailed} value={elem.dexName} control={<Radio color="primary" />} label={generateRadioLabel(elem)} />;
            })}
          </RadioGroup>
        </Grid>
          <Fade in={hasFailed && !isLoading}>
            <FormHelperText error>{failMsg}</FormHelperText>
          </Fade>
      </Grid>
    </Grid>
  );
};
