/**
 * Defines interfaces for DEX Aggregators SDKs & APIs.
 * 
 * see more here:
 * - {@link https://github.com/paraswap/paraswap-sdk | Paraswap SDK}
 * - {@link https://docs.1inch.io/api/quote-swap | 1Inch API}
 *
 * @module Interfaces/DEXes
 */

/**
 * Identify a DEXes or DEXes aggregators used in our strategy.
 */
export type DEXes = "1inch" | "Paraswap";
/**
 * Identify the tokens to swap on {@link Actions/Harvest | harvest} action.
 */
export type RewardTokens = "CRV" | "CVX";

/**
 * describe a swap's rate on a particular DEX.
 */
export type SwapRateData = {
  dexName: DEXes;
  dexAddr: string;
  tokenIn: RewardTokens;
  amountIn: string;
  tokenOut: string;
  amountOut: string;
  rawTxData: string;
};

/**
 * A tuple holding 2 [[SwapRateData]].
 */
export type BestSwapRates = [SwapRateData, SwapRateData];
/**
 * Store 2 [[BestSwapRates]] instance, one for each [[RewardTokens]].
 */
export type AllSwapRates = {
  CRV: BestSwapRates;
  CVX: BestSwapRates;
};

/**
 * [[SwapeRateData]] constructor (for readability)
 * 
 * @param dexName 
 * @param dexAddr 
 * @param tokenIn 
 * @param amountIn 
 * @param tokenOut 
 * @param amountOut 
 * @param rawTxData 
 * @returns a [[SwapeRateData]] instance.
 */
export const createSwapeRateData = (dexName: string, dexAddr: string, tokenIn: RewardTokens, amountIn: string, tokenOut: string, amountOut: string, rawTxData: string): SwapRateData => {
   return { dexAddr: dexAddr, dexName: dexName as DEXes, tokenIn: tokenIn, amountIn: amountIn, tokenOut: tokenOut, amountOut: amountOut, rawTxData: rawTxData };
};
export const defaultSwapRateData = createSwapeRateData("...", "addr", "CRV", "...","CVX", "...", "0x0");


/**
 * 1Inch Types declaration
 * @todo find someone that does this work for us (npm package, ...)
 */
declare module OneInchAPICallTypes {
    export interface FromToken {
        symbol: string;
        name: string;
        decimals: number;
        address: string;
        logoURI: string;
    }

    export interface ToToken {
        symbol: string;
        name: string;
        decimals: number;
        address: string;
        logoURI: string;
    }

    export interface Tx {
        from: string;
        to: string;
        data: string;
        value: string;
        gas: number;
        gasPrice: string;
    }

    export interface RootObject {
        fromToken: FromToken;
        toToken: ToToken;
        toTokenAmount: string;
        fromTokenAmount: string;
        protocols: any[][][];
        tx: Tx;
    }
}

export type OneInchAPIObject = OneInchAPICallTypes.RootObject;
