/**
 * Defines a clickable Button That will enable or disable the
 * "{@link TestMode | Test mode}".
 * 
 * @module TestModeButton
 */

import React from "react";
import useStyles from "./styles";

import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import useTestMode from "../../store/TestMode";

/**
 * Generate a {@link https://material-ui.com/components/buttons/ | Button}
 * that will toggle the Test mode on or off. It internally modifies
 * the TestMode Store, using the [[`useTestMode`]] hook.
 *
 * @returns The TestModeButton JSX element.
 */
export const TestModeButton: React.FC = () => {
  // todo: style through properties (https://material-ui.com/guides/typescript/#usage-of-component-prop)
  const classes = useStyles();
  const { dispatch } = useTestMode();

  const onClick = () => {
    dispatch({ type: "toggle" });
  };

  return (
    <Button className={classes.rightSectionButtons} startIcon={<SettingsIcon />} onClick={onClick} color="primary" variant="contained" disableElevation>
      Toggle Test Mode
    </Button>
  );
};

export default TestModeButton;
