/**
 * Set the Approval before harvesting, in preparation for the swap when
 * reinvesting.
 *
 * @module Actions/Approval
 */

import { useState, useEffect } from "react";

import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import { Button, Checkbox, DialogContentText, FormControlLabel, Grid, Typography } from "@mui/material";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";

import useHarvestData from "../../../store/HarvestData";
import useInterval from "../../../hooks/useInterval";
import usePoolInfos from "../../../store/PoolInfos";
import { getDBItem } from "../../../utils/tokenInfos";
import tokenInfos from "../../../constants/tokenInfos";

import { approveClaimables, getAllowance, infiniteApproveCRVCVX, inWalletCRVCVX } from "../Approve";
import { RewardTokens } from "../../../interfaces/DEXes";
import { BN, toBN } from "../../../utils/BN";
import useStyles from "./styles";

// TokenInfo data
const crvInfo = tokenInfos.find(({ symbol }) => symbol === "CRV")!;
const cvxInfo = tokenInfos.find(({ symbol }) => symbol === "CVX")!;

export interface HarvestProps {
  setStateLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Approval: React.FC<HarvestProps> = (props) => {
  const classes = useStyles();
  const { setStateLoader } = props;
  const context = useWeb3React<Web3>();
  const { data: selectedPoolIndex } = usePoolInfos();
  const currentPool = getDBItem(selectedPoolIndex);
  const { data: harvestData, dispatch } = useHarvestData();

  const [isCRVInfApproved, setIsCRVInfApproved] = useState<boolean>(false);
  const [isCVXInfApproved, setIsCVXInfApproved] = useState<boolean>(false);
  const [useCRVInWallet, setUseCRVInWallet] = useState<boolean>(harvestData.inWalletCRV !== "0");
  const [useCVXInWallet, setUseCVXInWallet] = useState<boolean>(harvestData.inWalletCVX !== "0");

  const [tick, setTick] = useState(0);

  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 10000);

  useEffect(() => {
    const allowed = async () => {
      if (!context.library || !context.account) return;
      const contractAddr = process.env.REACT_APP_ADDRESS!;

      // infiniteApprovalLevel == maximum uint256 value right-shifted by 3.
      // this is used as a treshold to test if infinite approved have been done.
      const infiniteApprovalLevel = toBN("0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffff");

      const crvAllowance = await getAllowance(context.library, crvInfo.address, context.account, contractAddr);
      dispatch({ type: "CRVAllowance", payload: crvAllowance });
      if (toBN(crvAllowance).gte(infiniteApprovalLevel)) {
        setIsCRVInfApproved(true);
      }

      const cvxAllowance = await getAllowance(context.library, cvxInfo.address, context.account, contractAddr);
      dispatch({ type: "CVXAllowance", payload: cvxAllowance.toString() });
      if (toBN(cvxAllowance).gte(infiniteApprovalLevel)) {
        setIsCVXInfApproved(true);
      }
    };

    allowed();
  }, [tick, selectedPoolIndex, context.account]);


  const handleApproveHarvest = async (token: RewardTokens) => {
    setStateLoader(true);
    if (!context.library || !context.account) return;
    await approveClaimables(token, context.library, context.account, currentPool);
    setStateLoader(false);
  };

  const handleInfApprove = async (token: RewardTokens) => {
    setStateLoader(true);
    if (!context.library || !context.account) return;
    await infiniteApproveCRVCVX(token, context.library, context.account, token === "CRV" ? setIsCRVInfApproved : setIsCVXInfApproved);
    setStateLoader(false);
  };

  const renderApproveButtons = (token: RewardTokens) => {
    const isInfApproved = token === "CRV" ? isCRVInfApproved : isCVXInfApproved;
    if (isInfApproved) {
      return <DialogContentText className={classes.notNeededApproveInfoText}>Infinite approve done for {token}</DialogContentText>;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <Button variant="contained" onClick={() => handleApproveHarvest(token)} size="small" className={classes.approveButton} disabled={isInfApproved}>
            APPROVE ALL CLAIMABLE {token}
          </Button>
          <Typography style={{ alignSelf: "center" }}>OR</Typography>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => handleInfApprove(token)}
            className={classes.approveButton}
            disabled={isInfApproved}
            startIcon={<AllInclusiveOutlinedIcon />}
          >
            INFINITE APPROVE OF {token}
          </Button>
        </div>
      );
    }
  };

  const rendercheckBox = (token: RewardTokens) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={token === "CRV" ? useCRVInWallet : useCVXInWallet}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              const isCRV = event.target.name === "CRV";
              const includeWalletBalance = event.target.checked;
              if (!context.library || !context.account) {
                return;
              }
              isCRV ? setUseCRVInWallet(includeWalletBalance) : setUseCVXInWallet(includeWalletBalance);
              
              let inWalletAmounts: [BN, BN];
              if (includeWalletBalance) {
                inWalletAmounts = await inWalletCRVCVX(context.library, context.account);
              } else {
                inWalletAmounts = [toBN(0), toBN(0)];
              }
              dispatch({ type: isCRV ? "inWalletCRV" : "inWalletCVX", payload: inWalletAmounts[isCRV ? 0 : 1].toString() });
            }}
            name={token}
          />
        }
        label={"Include " + token + " in wallet"}
      />
    );
  };

  return (
    <>
      <Grid item className={classes.approveSection}>
        <Typography variant={"h6"} style={{ textAlign: "center" }}>
          Token Approval
        </Typography>
        {renderApproveButtons("CRV")}
        {renderApproveButtons("CVX")}
      </Grid>
      <Grid item className={classes.checkBoxSection}>
        {isCRVInfApproved && rendercheckBox("CRV")}
        {isCVXInfApproved && rendercheckBox("CVX")}
      </Grid>
    </>
  );
};

export default Approval;
