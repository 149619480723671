/**
 * Defines various tool functions & datas used within the whole app
 * for blockchain interactions.
 *
 * @module Utils/Helpers
 */

import Web3 from "web3";
import { OneInchAPIObject } from "../interfaces/DEXes";
import { ERC20 } from "../constants/abi";
import axios from "axios";

import { ParaSwap, SwapSide } from "paraswap";
import { RateOptions, BuildOptions, APIError, Transaction } from "paraswap/build/types";
import { OptimalRate } from "paraswap-core";

import { BN } from "./BN";
import { DEXes } from "../interfaces/DEXes";

/**
 * Calls the balanceOf() function of a given ERC20 token.
 * @param web3 a web3.js instance.
 * @param account the address of the user for whom we want to check the balance.
 * @param tokenAddr the address of the ERC20 token Smart Contract.
 * @returns The balance of the account.
 */
export const getBalanceOf = async (web3: Web3, account: string, tokenAddr: string) => {
    const token = new web3.eth.Contract(ERC20, tokenAddr);
    if (token === undefined) throw new Error("ERC20 contract not found at address " + tokenAddr);

    const balance = web3.utils.toBN(await token.methods.balanceOf(account).call());

    return balance.toString();
};

/**
 * Encodes a path to swap according to Uniswap V3 API.
 * @param path Raw path to be encoded for uniswap V3
 * @param fees Fees encoded
 * @returns The hexadecimal encoded string path
 */
export const encodePathUniV3 = (path: string[], fees: number[]): string => {
    const FEE_SIZE = 3;
    if (path.length !== fees.length + 1) {
        throw new Error("Path and Fees Lengths do not match");
    }
    let encoded = "0x";
    for (let i = 0; i < fees.length; i++) {
        encoded += path[i].slice(2);
        encoded += fees[i].toString(16).padStart(2 * FEE_SIZE, "0");
    }
    encoded += path[path.length - 1].slice(2);
    return encoded.toLowerCase();
};

/**
 * Describe how swap should be proceed
 * 
 * dexAggregator: string describing which DEX aggregator to choose\
 * maxSlippage: slippage tolerance
 * buildOptionsParaswap: Paraswap-specific options for custom Tx build behaviors
 * rateOptionsParaswap: Paraswap-specific options for custom swap rate behaviors (e.g. price impact)
 * feesInBip: Determine the fee taking by Harvex.xyz on Harvest.
 */
interface SwapConfig {
    dexAggregator: DEXes;
    maxSlippage: number;
    buildOptionsParaswap: BuildOptions;
    rateOptionsParaswap: RateOptions;
    feesInBips: number;
}

export const swapConfig: SwapConfig = {
    dexAggregator: "1inch",
    maxSlippage: 10,
    rateOptionsParaswap: {
        maxImpact: 10,
    },
    buildOptionsParaswap: { ignoreChecks: true },
    // fees: 0.1%
    feesInBips: 10,
};

/**
 * Estimate the CVX earned depending on an amount of CRV harvested.
 * @param crvEarned the amount of CRV earned at some time.
 * @param cvxTotalSupply the CVX.TotalSupply() returned value.
 * @returns The estimated claimable amount of CVX.
 */
export const getCVXMintAmount = (crvEarned: BN, cvxTotalSupply: BN): BN => {
    let cliffSize = Web3.utils.toBN("100000000000000000000000"); // New cliff every 100 000 tokens
    let cliffCount = 1000; // 1 000 cliffs
    let maxSupply = Web3.utils.toBN("100000000000000000000000000"); // 100 mil max supply
    // First get total supply
    // let cvxTotalSupply = await cvx.totalSupply();
    // Get current cliff
    let currentCliff = cvxTotalSupply.div(cliffSize);
    // If current cliff is under the max
    if (currentCliff.ltn(cliffCount)) {
        // Get remaining cliffs
        let remaining = Web3.utils.toBN(cliffCount).sub(currentCliff);
        // Multiply ratio of remaining cliffs to total cliffs against amount CRV received
        var cvxEarned = crvEarned.mul(remaining).divn(cliffCount);
        // Double check we have not gone over the max supply
        var amountTillMax = maxSupply.sub(cvxTotalSupply);
        if (cvxEarned.gt(amountTillMax)) {
            cvxEarned = amountTillMax;
        }
        return cvxEarned;
    }
    return Web3.utils.toBN(0);
};

/**
 * Call the Paraswap API to build a swap transaction.
 * @param tokenFrom Address of the input ERC20 token
 * @param tokenFromDecimals Number of decimals for this ERC20
 * @param tokenTo Address of the output ERC20 token
 * @param tokenToDecimals Number of decimals for this ERC20
 * @param amountIn input amount of token
 * @param referrer referrer collecting fees
 * @param signer Recipient & Signer {@todo might be useful to separate}
 * @param rateOptions Options for the swap rate request
 * @param buildOptions Options to custom buildTx request
 * @return the result of sdk.getRate() & sdk.buildTx()
 */
export const getParaswapTx = async (
    tokenFrom: string,
    tokenFromDecimals: number,
    tokenTo: string,
    tokenToDecimals: number,
    amountIn: string,
    referrer: string,
    signer: string,
    rateOptions?: RateOptions,
    buildOptions?: BuildOptions
): Promise<[OptimalRate | APIError, Transaction | APIError]> => {
    const sdk = new ParaSwap(1);

    const priceRoute = await sdk.getRate(tokenFrom, tokenTo, amountIn, signer, SwapSide.SELL, rateOptions ?? swapConfig.rateOptionsParaswap, tokenFromDecimals, tokenToDecimals);
    if ("message" in priceRoute) {
        console.log("APIError received on ParaSwap.getRate().");
        console.log("Status " + (priceRoute.status ?? "unknown") + " Paraswap getRate");
        console.log("Message: " + priceRoute.message);
        return [priceRoute, priceRoute];
    }

    if (priceRoute.maxImpactReached === true) {
        console.log("Max Impact Reached");
        const error: APIError = {
            message: "Paraswap API: Max Impact Reached",
            status: 0,//todo check if used somewhere
        };
        return [error, error];
    }
    const amountOutMin = Web3.utils
        .toBN(priceRoute.destAmount)
        .muln(100 - swapConfig.maxSlippage)
        .divn(100);
    const rawTx = await sdk.buildTx(tokenFrom, tokenTo, amountIn, amountOutMin.toString(), priceRoute, signer, undefined, undefined, undefined, signer, buildOptions ?? swapConfig.buildOptionsParaswap, tokenFromDecimals, tokenToDecimals, undefined, (new Date().getTime() + 20*60*1000).toString());
    return [priceRoute, rawTx];
};

/**
 * Call the 1Inch API to build a swap transaction.
 * @param fromTokenAddress Address of the input ERC20 token
 * @param toTokenAddress Address of the output ERC20 token
 * @param amountIn input amount of token
 * @param fromAddress spender Address
 * @param slippage price variation tolerance (in %)
 * @return the built transaction and the axios request status
 */
export const getOneInchDataTx = async (
    fromTokenAddress: string,
    toTokenAddress: string,
    amountIn: string,
    fromAddress: string,
    slippage: number
): Promise<[OneInchAPIObject, number]> => {
    const baseURL = "https://api.1inch.exchange/v3.0/1/swap";
    const disableEstimate = true;
    const requestParam = {
        params: {
            fromTokenAddress: fromTokenAddress,
            toTokenAddress: toTokenAddress,
            amount: amountIn,
            fromAddress: fromAddress,
            slippage: slippage,
            disableEstimate: disableEstimate,
        },
    };
    let res = await axios.get<OneInchAPIObject>(baseURL, requestParam);
    if (res.status >= 500) {
        // 1Inch sometimes gives us a status 500. let's try one more time
        res = await axios.get<OneInchAPIObject>(baseURL, requestParam);
    }
    return [res.data, res.status];
};

const Helpers = {
    swapConfig,
    encodePathUniV3,
    getBalanceOf,
    getCVXMintAmount,
    getParaswapTx,
    getOneInchDataTx,
};

export default Helpers;
