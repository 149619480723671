import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        rightSectionButtons: {
            margin: 15,
            fontSize: "20",
            fontFamily: "Atkinson Hyperlegible",
        },
    })
);
