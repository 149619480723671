/**
 * Definition of the [[useInterval]] hook.
 * @module Hooks/useInterval
 */

import React, { useEffect, useRef } from 'react';

type Callback = () => void;

/**
 * Execute a callback every 'delay' milliseconds.
 * @param callback the code to execute periodically.
 * @param delay the time lapse between two 'callback' execution
 * (in milliseconds).
 */
export const useInterval = (callback: Callback, delay: number) => {
  const savedCallback = useRef<Callback>(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;