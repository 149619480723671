/**
 * provides addresses used to instanciate contracts in the source code.
 *
 * @module Constants/addresses
 */

export const EURS = "0xdB25f211AB05b1c97D595516F45794528a807ad8";
export const SEUR = "0xD71eCFF9342A5Ced620049e616c5035F1dB98620";
export const WETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const USDC = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
export const CVX = "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b";
export const CRV = "0xD533a949740bb3306d119CC777fa900bA034cd52";
export const CONVEXEUROPOOL = "0xcB8F69E0064d8cdD29cbEb45A14cf771D904BcD3";
export const CURVEEUROPOOL = "0x0Ce6a5fF5217e38315f87032CF90686C96627CAA";
export const TokenTransferProxy = "0x216b4b4ba9f3e719726886d34a177484278bfcae";
export const AugustusSwaper = "0x1bD435F3C054b6e901B7b108a0ab7617C808677b";
export const AugustusSwaperV5 = "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57";
export const DAI = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
export const CurveRegistry = "0x90E00ACe148ca3b23Ac1bC8C240C2a7Dd9c2d7f5";
export const oneInchSwaper = "0x111111125434b319222CdBf8C261674aDB56F3ae";
export const oneInchSwaperApprove = "0x11111112542D85B3EF69AE05771c2dCCff4fAa26";

export default {
    EURS,
    SEUR,
    WETH,
    USDC,
    CRV,
    CVX,
    CONVEXEUROPOOL,
    CURVEEUROPOOL,
    TokenTransferProxy,
    AugustusSwaper,
    CurveRegistry,
    oneInchSwaper,
    oneInchSwaperApprove,
};
