/**
 * Approvals Handler
 * 
 * Allows one to approve an infinite amount of token spending.
 * It reduces the gas cost and the number of tx in the future,
 * but can presents some security issues.
 * 
 * @module Actions/web3/Approve
 */

import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { CONVEXPOOL as ABIConvexPooL, ERC20 } from "../../constants/abi";

import tokenInfos from "../../constants/tokenInfos";
import DBItem from "../../interfaces/DBItem";
import { getCVXMintAmount } from "../../utils/helpers";
import { BN, toBN } from "../../utils/BN";


export const inWalletCRVCVX = async (web3: Web3, account: string): Promise<[BN, BN]> => {
    const crvA = tokenInfos.find(({ symbol }) => symbol === "CRV");
    const cvxA = tokenInfos.find(({ symbol }) => symbol === "CVX");
    if (!crvA || !cvxA) return [toBN(0), toBN(0)];
    const crv = new web3.eth.Contract(crvA.abi, crvA.address);
    const cvx = new web3.eth.Contract(cvxA.abi, cvxA.address);
    return [toBN(await crv.methods.balanceOf(account).call()), toBN(await cvx.methods.balanceOf(account).call())];
};

export const getAllowance = async (web3: Web3, tokenAddress: string, fromAccount: string, toAccount: string): Promise<string> => {
    const token = new web3.eth.Contract(ERC20, tokenAddress);
    const currentAllowance = await token.methods.allowance(fromAccount, toAccount).call({ from: fromAccount });
    return currentAllowance;
};

export const isInfinitelyApproved = async (web3: Web3, tokenAddress: string, fromAccount: string, toAccount: string): Promise<boolean> => {
    // infiniteApprovalLevel == maximum uint256 value right-shifted by 3.
    // this is used as a treshold to test if infinite approved have been done.
    const infiniteApproveTreshold = toBN("0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
    const allowed = await getAllowance(web3, tokenAddress, fromAccount, toAccount);

    return toBN(allowed).gt(infiniteApproveTreshold);
};

/**
 * Allows our smart contract 'ConvexStrategist' to spend a (virtual) infinite
 * amount of CRV or CVX tokens, on the behalf of someone else.
 * 
 * @param tokenName  : The Token identifier.
 * @param web3       : A Web3js instance.
 * @param account    : The account that allows the token spending.
 */
export const infiniteApproveCRVCVX = async (tokenName: "CRV" | "CVX", web3: Web3, account: string, setStateBack: React.Dispatch<React.SetStateAction<boolean>>) => {

    const contractAddr = process.env.REACT_APP_ADDRESS!;
    const tokenInfo = tokenInfos.find(({ symbol }) => symbol === tokenName);
    if (!tokenInfo) throw new Error("Tokens not found");
    const tokenInstance = new web3.eth.Contract(tokenInfo.abi as AbiItem[], tokenInfo.address);
    const approveAmount = toBN("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
    const currentAllowance = toBN(await tokenInstance.methods.allowance(account, contractAddr).call({ from: account }));
    // console.log("currentAllowance : " + currentAllowance.toString());
    await approveIfNecessary(web3, tokenInstance.options.address, approveAmount, contractAddr, account);
    setStateBack(true);
};

export const approveIfNecessary = async (web3: Web3, tokenAddress: string, amount: BN, toAddress: string, account: string) => {
    const token = new web3.eth.Contract(ERC20, tokenAddress);
    const currentAllowance = toBN(await token.methods.allowance(account, toAddress).call({ from: account }));
    if (amount.gt(currentAllowance)) {
        if (currentAllowance.isZero()) {
            console.log("allowance was 0");
            await token.methods.approve(toAddress, amount).send({ from: account });
        } else {
            console.log("allowance was > 0 => approve(0) first");
            await token.methods.approve(toAddress, "0").send({ from: account });
            await token.methods.approve(toAddress, amount).send({ from: account });
        }
    } else return console.log("Approve not necessary");
};

export const computeHarvest = async (web3: Web3, account: string, currentStrat: DBItem): Promise<[BN, BN]> => {
    const convexPool = new web3.eth.Contract(ABIConvexPooL as AbiItem[], currentStrat.convexPooL);
    const cvxInfo = tokenInfos.find(({ symbol }) => symbol === "CVX");
    if (!cvxInfo) throw new Error("cvxInfo not found");
    const cvx = new web3.eth.Contract(cvxInfo.abi, cvxInfo.address);
    const crvToHarvest = toBN(await convexPool.methods.earned(account).call());
    const currentSupply = toBN(await cvx.methods.totalSupply().call());
    const cvxToHarvest = await getCVXMintAmount(crvToHarvest, currentSupply);
    return [crvToHarvest, cvxToHarvest];
};

// DEFAULT APPROVE - 2 PER HARVEST CRV/CVX

export const approveClaimables = async (tokenName: "CRV" | "CVX", web3: Web3, account: string, currentStrat: DBItem) => {
    const contractAddr = process.env.REACT_APP_ADDRESS!;
    const tokenInfo = tokenInfos.find(({ symbol }) => symbol === tokenName);
    if (!tokenInfo) throw new Error("Tokens not found");
    const token = new web3.eth.Contract(tokenInfo.abi as AbiItem[], tokenInfo.address);
    const [crvToHarvest, cvxToHarvest] = await computeHarvest(web3, account, currentStrat);
    if (crvToHarvest.isZero() || cvxToHarvest.isZero()) {
        alert("Invalid : Amount to approve is 0");
        return console.log("Invalid : Amount to approve is 0");
    }
    await approveIfNecessary(web3, token.options.address, tokenName === "CRV" ? crvToHarvest : cvxToHarvest, contractAddr, account);
};

export default { inWalletCRVCVX, isInfinitelyApproved, getAllowance, infiniteApproveCRVCVX, computeHarvest, approveIfNecessary, approveClaimables };
