import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: theme.palette.background.paper,
        },
        Panel: {
            display: "flex",
            backgroundColor: "#f5f5f5",
            alignSelf: "center",
            // padding: theme.spacing(1)/2,
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        PanelText: {
        },
        AppBar: {
            marginBottom: theme.spacing(2),
        },
        Dialog: {
        }
    })
);
