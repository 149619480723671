/**
 * Defines a display for token balances, including each token related to the selected pool.
 * 
 * @module Balances
 */

// MATERIAL-UI
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Fade, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import "@fontsource/roboto";

// WEB3
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";

// LOCAL
import { getPoolTokenInfos, getReinvestedTokenByPool } from "../../utils/tokenInfos";
import usePoolInfos from "../../store/PoolInfos";
import computeBalances, { DEFAULT_BALANCES } from "./data";
import useInterval from "../../hooks/useInterval";
import useStyles from "./styles";
import useTestMode from "../../store/TestMode";
import swapETHForTokens from "./swapETHForTokens";
import { setFloatPrecision } from "../../utils/BN";
import usePopup from "../Popup";

/**
 * Generates the 'Balances' module, which displays the amount of token the user has
 * for each relevant token (according to the selected pool).
 *
 * @returns a {@link https://material-ui.com/components/grid/ | Grid} displaying all balances
 * in separate {@link https://material-ui.com/components/paper/ | Papers}.
 */
export const Balances: React.FC = () => {
  const classes = useStyles();
  const [tick, setTick] = useState(0);
  const [loader, setLoader] = useState(false);
  const context = useWeb3React<Web3>();
  const [openPopup] = usePopup();
  const { data: selectedPoolIndex } = usePoolInfos();
  const { data: isInTestMode } = useTestMode();

  const tokenInfos = getPoolTokenInfos(selectedPoolIndex, isInTestMode);
  const [balances, setBalances] = useState<string[]>(DEFAULT_BALANCES);

  const buyToken = async (name: String, web3Context: Web3ReactContextInterface<Web3>) => {
    let res: boolean;

    setLoader(true);
    try {
      res = await swapETHForTokens(name, web3Context, selectedPoolIndex);
    } catch (e) {
      res = false;
    }
    openPopup(res ? "Token purchase success!" : "Error while purchasing token.", res ? "info" : "error");
    setLoader(false);
  };

  // This interval will update tick every 10 seconds
  // Todo: compute average time for fetching balances,
  // And use it with a timeout
  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 10000);

  // Tick will trigger rerendering
  useEffect(() => {
    computeBalances(context, tokenInfos).then((newBalances) => setBalances(newBalances));
  }, [tick, selectedPoolIndex, context.account, context.library, context.chainId, isInTestMode]);

  return (
    <Grid item component={Paper} variant="outlined" className={classes.container}>
      <Typography className={classes.title} color="textPrimary">
        <strong>Balances</strong>
      </Typography>
      {tokenInfos.map((tokenInfo, index) => {
        return (
          <Paper key={index} elevation={3} className={classes.paperBalance}>
            <Typography className={classes.balanceValue}>{setFloatPrecision(balances[index], 3) + " " + tokenInfo.symbol}</Typography>
            <Fade in={isInTestMode && (tokenInfo.symbol === "WETH" || getReinvestedTokenByPool(selectedPoolIndex).addr === tokenInfo.address)}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={loader}
                className={classes.balanceButton}
                onClick={() => buyToken(tokenInfo.symbol, context)}
              >
                ADD
              </Button>
            </Fade>
          </Paper>
        );
      })}

      <Fade in={loader}>
        <LinearProgress variant="indeterminate" />
      </Fade>
    </Grid>
  );
};

export default Balances;