/**
 * Defines & exports the config() function, which allow various initialization
 * on program start.
 *
 * @module Utils/Config
 */

/**
 * Setup a proxy to make it works with Paraswap SDK.
 * @returns false if an error occured, true otherwise.
 */
export const paraswapProxyConfig = () => {
    const result = process.env.REACT_APP_HTTP_PROXY !== undefined;
    if (result) {
        const Axios = require("paraswap/../../node_modules/axios");
        const HttpsProxyAgent = require("https-proxy-agent");
        Axios.defaults.httpsAgent = new HttpsProxyAgent(process.env.REACT_APP_HTTP_PROXY);
        Axios.defaults.proxy = false;
        // Work around for certificate validation error
        process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    }
    return result;
};

/**
 * Initialize modules & libraries. It must be called only once.
 */
export const config = () => {
    paraswapProxyConfig();
};

export default config;
