/**
 * Provides statistics about the gas costs of a [[Actions/web3/Harvest]] operation.
 * It compares the manual method and our smart contract method.
 *
 * Gas costs are computed from an independant script that runs
 * 'web3.Contract.methods.estimateGas' on each transactions.
 *
 * @module Statistics
 */

import React, { useState, useEffect } from "react";
import useStyles from "./styles";

// MATERIAL UI
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import EvStationIcon from "@mui/icons-material/EvStation";

// WEB3
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

// LOCAL
import axios from "axios";
import { CHAINLINK_ORACLE as CHAINLINK_ABI } from "../../constants/abi";
import useInterval from "../../hooks/useInterval";
import { UserInfo } from "./UserInfo";

interface ActionCost {
  name: string;
  estimateGasCost: number;
  realGasCost: number;
}


const gasPriceURL = "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=JRJ4W6GJIF79JA4UJS9IY8YJ7ENT9R2KV5";

/**
 * Response format from Etherscan API
 */
interface EtherscanAPIResponse {
  LastBlock: string;
  SafeGasPrice: string;
  ProposeGasPrice: string;
  FastGasPrice: string;
  suggestBaseFee: string;
  gasUsedRatio: string;
}

/**
 * ORACLE_ADDRESS : Chainlink ETH USD Oracle
 */
const ORACLE_ADDRESS = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";

/**
 * Extracted from backend script via estimateGas web3 function
 * gasLogsManual : each tx from manual harvest
 * gasLogsAuto : cost of harvest function, + 2 approvals
 */
const gasLogsManual: ActionCost[] = [
  { name: "Get Reward", estimateGasCost: 145325, realGasCost: 129869 },
  { name: "Approve CRV", estimateGasCost: 45124, realGasCost: 45124 },
  { name: "Approve CVX", estimateGasCost: 44119, realGasCost: 44119 },
  { name: "Swap CRV", estimateGasCost: 190868, realGasCost: 132951 },
  { name: "Swap CVX", estimateGasCost: 190868, realGasCost: 132951 },
  { name: "Approve Curve", estimateGasCost: 44082, realGasCost: 44082 },
  { name: "Add Liquidity Curve", estimateGasCost: 187170, realGasCost: 140669 },
  { name: "Approve Curve LP Token", estimateGasCost: 44951, realGasCost: 44951 },
  { name: "Deposit + Stake in Convex", estimateGasCost: 410461, realGasCost: 278964 },
];

const gasLogsAuto: ActionCost[] = [
  {
    name: "Approve CRV (if no ∞ approved)",
    estimateGasCost: 45124,
    realGasCost: 45124,
  },
  {
    name: "Approve CVX (if no ∞ approved)",
    estimateGasCost: 44119,
    realGasCost: 44119,
  },
  {
    name: "One-Click Harvest & Stake",
    estimateGasCost: 1053650,
    realGasCost: 734161,
  },
];

/**
 * Get Chainlink Oracle ETH price in USD.
 *
 * @param web3 : web3 instance
 * @returns ETH price in USD
 */
const getOracleETHPrice = async (web3: Web3) => {
  let oracle = new web3.eth.Contract(CHAINLINK_ABI, ORACLE_ADDRESS);
  let ethPrice = await oracle.methods.latestAnswer().call();
  return ethPrice / 10 ** 8;
};

/**
 * Compute the USD gas cost.
 *
 * @param gasCost sum of gas to spend
 * @param currentGasPrice gas price in ETH
 * @param etherPrice ETH price in USD
 * @returns the total USDT cost for a given amount of gas
 */
const gasToDollars = (gasCost: number, currentGasPrice: number, etherPrice: number): string => {
  const usdCostPerGWei = etherPrice / 10 ** 9;
  const costUSD = usdCostPerGWei * gasCost * currentGasPrice;
  return Math.floor(costUSD).toString();
};

type CostDetailModalProps = {
  data: ActionCost[];
  title: string;
  gasPrice: string;
  etherUSDPrice: number;
};

const CostDetailModal: React.FC<CostDetailModalProps> = (props): JSX.Element => {
  const classes = useStyles();
  const TotalRealGasCost = props.data.reduce((s, a) => {
    return s + a.realGasCost;
  }, 0);

  return (
    <Grid item className={classes.modalContentContainer}>
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      {props.data.map(({ name, estimateGasCost, realGasCost }, index) => {
        return (
          <Paper key={index} elevation={2} className={classes.modalContentPaper}>
            <DialogContentText className={classes.modalContentPaperTypo} color="initial">
              {name}
            </DialogContentText>
            <DialogContentText className={classes.modalContentPaperTypo} color="initial">
              ~{gasToDollars(realGasCost, parseInt(props.gasPrice), props.etherUSDPrice)}$
            </DialogContentText>
          </Paper>
        );
      })}
      <Paper elevation={3} className={classes.altModalContentPaper}>
        <Typography className={classes.modalContentPaperTypo} color="secondary">
          TOTAL ({TotalRealGasCost} gas)
        </Typography>
        <Typography className={classes.modalContentPaperTypo} color="secondary">
          ~{gasToDollars(TotalRealGasCost, Math.round(parseInt(props.gasPrice)), props.etherUSDPrice)}$
        </Typography>
      </Paper>
    </Grid>
  );
};

/**
 * Generates the 'Statistics' module, which display the gas costs of a harvest operation.
 *
 * @returns 2 {@link https://material-ui.com/components/buttons/ | Buttons}
 * that display a 'CostDetailModal', as well as a {@link Statistics/UserInfo | User Info button}.
 */
export const Statistics: React.FC = () => {
  const classes = useStyles();
  const context = useWeb3React<Web3>();

  const [tick, setTick] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [costDetailData, setCostDetailData] = useState<ActionCost[]>([]);
  const [costDetailTitle, setCostDetailTitle] = useState<string>("");
  const [currentGasPrice, setCurrentGasPrice] = useState<string>("");
  const [etherUSDPrice, setEtherUSDPrice] = useState(0);

  // this interval will update tick every 10 seconds
  // NB: Etherscan API updates every 8 seconds
  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 10000);

  useEffect(() => {
    (async function getGasPrice() {
      const res = await axios.get(gasPriceURL);
      const EtherscanData: EtherscanAPIResponse = res.data.result;
      setCurrentGasPrice(EtherscanData.ProposeGasPrice);
    })();
    if (context.library) {
      getOracleETHPrice(context.library).then((value) => setEtherUSDPrice(value));
    }
  }, [context.library, tick]);

  const onManualGasCostClick = () => {
    setCostDetailData(gasLogsManual);
    setCostDetailTitle("Manual Harvest Cost Detail");
    setIsModalOpen(true);
  };

  const onOneClickGasCostClick = () => {
    setCostDetailData(gasLogsAuto);
    setCostDetailTitle("One-click Harvest Cost Detail");
    setIsModalOpen(true);
  };

  return (
    <Grid container item className={classes.root}>
      <Grid item xs={12} className={classes.statsContainer}>
        <Paper onClick={onManualGasCostClick} elevation={5} className={classes.statButton}>
          <Typography className={classes.statButtonTypo} color="secondary">
            Manual Gas Cost
          </Typography>
          <EvStationIcon />
          <Typography className={classes.statButtonTypo} color="secondary">
            ~
            {gasToDollars(
              gasLogsManual.reduce((s, a) => s + a.realGasCost, 0),
              parseInt(currentGasPrice),
              etherUSDPrice
            )}
            $
          </Typography>
        </Paper>
        <UserInfo />
        <Paper onClick={onOneClickGasCostClick} elevation={5} className={classes.statButton}>
          <Typography className={classes.statButtonTypo} color="secondary">
            OneClick Gas Cost
          </Typography>
          <EvStationIcon />
          <Typography className={classes.statButtonTypo} color="secondary">
            ~
            {gasToDollars(
              gasLogsAuto.reduce((s, a) => s + a.realGasCost, 0),
              parseInt(currentGasPrice),
              etherUSDPrice
            )}
            $
          </Typography>
        </Paper>
      </Grid>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CostDetailModal data={costDetailData} title={costDetailTitle} gasPrice={currentGasPrice} etherUSDPrice={etherUSDPrice} />
      </Dialog>
    </Grid>
  );
};

export default Statistics;
