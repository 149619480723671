/**
 * Defines a customizable popup to display simple information.
 *
 * @module Popup
 */

import React from "react";
import { Snackbar } from "@mui/material";
import useStyles from "./styles";
import Alert, { AlertColor as Severity } from '@mui/material/Alert';
import usePopupData from "../../store/PopupData";

/**
 * Generates the 'Popup' module, which display temporary informations to the user.
 *
 * @returns a {@link https://mui.com/components/snackbars/ | snackbar}.
 */
export const Popup: React.FC = () => {
  const classes = useStyles();
  const { data: popupData, dispatch } = usePopupData();

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "isOpen", payload: false });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseSnack} open={popupData.isOpen} autoHideDuration={5000}>
      <Alert style={{maxWidth: 400, overflowWrap: "anywhere"}} onClose={handleCloseSnack} severity={popupData.severity}>
      {popupData.message}
      </Alert>
    </Snackbar>
  );
};

type usePopupReturnValueType = [(message?: string, severity?: Severity) => void, (message: string) => void, (severity: string) => void]
/**
 * Provides utility functions through a Hook to manage the [[Popup]] Module.
 *
 * @returns the utility functions in an array. \([openPopup, setMessage, setSeverity]\)
 */
export const usePopup: () => usePopupReturnValueType = () => {
  const { data: popupData, dispatch } = usePopupData();

  const setMessage = (message: string) => dispatch({ type: "message", payload: message });
  const setSeverity = (severity: string) => dispatch({ type: "severity", payload: severity });
  const openPopup = (message?: string, severity?: Severity) => {
    if (popupData.isOpen) {
      dispatch({ type: "isOpen", payload: false });
    }
    if (!!message) {
      setMessage(message);
    }

    if (!!severity) {
      setSeverity(severity);
    }

    dispatch({ type: "isOpen", payload: true });
  };

  return [openPopup, setMessage, setSeverity];
};

export default usePopup;
