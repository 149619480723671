/**
 * Defines connectors (see {@link https://github.com/NoahZinsmeister/web3-react
 * | web3-react} documentation).
 *
 * @module Utils/connectors
 */

import { InjectedConnector } from "@web3-react/injected-connector";

import { SupportedChainId } from "../constants/chains";

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [SupportedChainId.MAINNET];

export const injected = new InjectedConnector({
    supportedChainIds: SUPPORTED_CHAIN_IDS,
});
