/**
 * Defines all types, data and hook relative to the Test mode Store
 * (see {@link Interfaces/Store}).
 * 
 * This Store manage the state of the "Test mode", which will activate
 * additional features that help for live testing, e.g. token purchases.
 * @module Store/TestMode
 */

import React, { createContext, useContext } from "react";

import DataStore, { IActionWithoutPayload, IReducer } from "../interfaces/Store";

type Data = boolean;

type ActionType = "toggle";
type Action = IActionWithoutPayload<ActionType>;

type Store = DataStore<Data, Action>;

/**
 * Possible actions:
 * - "toggle": switch the state value (on/off).
 * 
 * @param prevState The current state
 * @param action The state update to apply
 * @returns the updated state
 */
export const reducer: IReducer<Data, Action> = (state, action) => {
    let newState = state;

    switch (action.type) {
        case "toggle":
        default:
            newState = !state;
            break;
    }

    return newState;
};

export const DEFAULT_DATA: Data = false;

export const DEFAULT_STATE: Store = {
    data: DEFAULT_DATA,
    dispatch: (action: Action) => {
        throw "TestMode: Store.dispatch was not set.";
    },
};

/**
 * React context instance to access the data.
 * It is however recommended to use the [[useTestMode]] hook instead.
 */
export const context = createContext<Store>(DEFAULT_STATE);

/**
 * Abstract the context use with a hook-like function.
 * @returns The current state of the store.
 */
export const useTestMode = () => {
    const store = useContext(context);
    return store;
};

export default useTestMode;
