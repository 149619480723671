/**
 * Defines a clickable button to allow Metamask connection.
 * 
 * @module MetamaskButton
 */

import React from "react";
import useStyles from "./styles";

import Button from "@mui/material/Button";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../utils/connectors";

/**
 * Generates the 'MetamaskButton' module, which allow the user to connect its metamask account
 * to the website.
 *
 * @todo style through properties (https://material-ui.com/guides/typescript/#usage-of-component-prop)
 * @returns a {@link https://material-ui.com/components/buttons/ | Button}.
 */
export const MetamaskButton: React.FC = () => {
  const classes = useStyles();
  const context = useWeb3React();

  const onClickConnect = () => {
    context.activate(injected);
  };

  if (context.error) {
    console.log("error in Web3React context: ", context.error);
  }

  return (
    <Button className={classes.rightSectionButtons} startIcon={<AccountBoxIcon />} disabled={context.active} onClick={onClickConnect} variant="contained">
      {context.active ? "Connected" : "Connect to Metamask"}
    </Button>
  );
};

export default MetamaskButton;
