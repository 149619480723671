import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        simpleDialogTitle: {
            textAlign: "center",
        },
        modalActions: {
            justifyContent: "center",
            margin: theme.spacing(1),
        },
    })
);
