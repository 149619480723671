/**
 * Defines the user information display about gas costs of a Harvest action.
 *
 * @module Statistics/UserInfo
 */

import { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import HelpButton from "../HelpButton";

/**
 * Provides information to the user about the gas costs.
 * 
 * @returns a {@link https://material-ui.com/components/buttons/ | Button}
 * associated with a {@link https://material-ui.com/components/dialogs/ | Dialog}
 * providing informations.
 */
export const UserInfo: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HelpButton
        onClick={() => {
          setIsModalOpen(true);
        }}
        style={{ transform: "scale(1.42)", marginLeft: 12, marginRight: 12 }}
      />
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle style={{ textAlign: "center" }}>Gas Costs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            These <b>2 buttons</b> will show a summary of what a Harvest & Reinvest operation would cost you in dollars.
          </DialogContentText>
          <DialogContentText>
            In order to compute this, We first retrieved the gas cost for each operation. We then multiplied it by the current gas price (with live update).
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserInfo;
