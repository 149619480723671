/**
 * Provides an abstraction to access the db.json object.
 *
 * @module Constants/strategyDataBase
 */

import DBItem from "../interfaces/DBItem";
import data from "./db.json";

/**
 * The database (db.json) gather each Curve pools and informations about them.
 * Details about its construction are given in the
 * {@link Interfaces/DBItem | interface of DBItem}.
 */
export const dataBase: DBItem[] = data.filter((strat) => strat.active);

export default dataBase;
