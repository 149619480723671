/**
 * Remove Handler
 *
 * Inform the user about the removal process (can't be done by the smart contract
 * because Convex doesn't allow to remove on the behalf of user)
 *
 * @module Actions/web3/Remove
 */

import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useStyles from "./styles";

export interface RemoveProps {
  setStateLoader: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export const RemoveDialog: React.FC<RemoveProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle className={classes.simpleDialogTitle}>Remove Process</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Convex doesn't allow to remove on the behalf of a user, thus, we cannot aggregate the multiple required transactions in one smart contract function.
        </DialogContentText>
        <DialogContentText>
          Here is the process for unstaking and withdraw your liquidity:<br />
          • Unstake + Remove from Convex
          <br />
          • Remove from Curve
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button onClick={() => window.open("https://www.convexfinance.com/", "_blank")} color="primary" variant="contained" startIcon={<OpenInNewIcon />}>
          Convex
        </Button>
        <Button onClick={() => window.open("https://curve.fi/", "_blank")} color="primary" variant="contained" startIcon={<OpenInNewIcon />}>
          Curve
        </Button>
      </DialogActions>
    </>
  );
};

export default RemoveDialog;
