import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modalContentContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "space-between",
        },
        dialogTitle: {
            fontFamily: "Atkinson Hyperlegible",

            textAlign: "center",
        },
        modalContentPaper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // margin: theme.spacing(1) / 2,
            margin: theme.spacing(1),
            width: "90%",
        },
        altModalContentPaper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // margin: theme.spacing(1) / 2,
            margin: theme.spacing(1),
            marginBottom: theme.spacing(2),
            width: "90%",
            backgroundColor: "#9856B4",
        },
        modalContentPaperTypo: {
            margin: theme.spacing(1),
            fontSize: 14,
        },
        titleTypo: {
            textAlign: "center",
            width: "100%",
        },
        statsContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: theme.spacing(1),
        },
        statButton: {
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#9856B4",
        },
        statButtonTypo: {
            fontFamily: "Roboto",
            margin: theme.spacing(1),
        },
        gasIcon: {
            // NB: padding will resizes the icon
            paddingTop: 10,
        },
    })
);
