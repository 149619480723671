/**
 * Defines the container layout for the 'Actions', the main services
 * that are proposed by the application
 * ({@link Actions/web3/Deposit | Deposit},
 * {@link Actions/web3/Harvest | Harvest} and
 * {@link Actions/web3/Remove | Remove}).
 *
 * @module Actions
 */

import React, { useState, useEffect, useReducer } from "react";

// MATERIAL UI
import LinearProgress from "@mui/material/LinearProgress";
import { Button, Dialog, Fade, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

// WEB3
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

// LOCAL
import { DepositDialog } from "./Deposit";
import HarvestDialog from "./Harvest";
import RemoveDialog from "./Remove";
import * as HarvestData from "../../store/HarvestData";
import usePoolInfos from "../../store/PoolInfos";
import useStyles from "./styles";

// Todo : DRY : One interface for all modal specialization (./web3/[...].tsx)
interface ModalContentProps {
  setStateLoader: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

/**
 * Generate the 'Actions' module, which allow the user to perform
 * any available actions through a
 * {@link https://material-ui.com/components/buttons/ | Button}.
 * Each button either trigger a transaction, or open a
 * {@link https://material-ui.com/components/dialogs/ | Dialog}
 * with additional informations and forms for the user.
 *
 * @returns a {@link https://material-ui.com/components/paper/ | Paper}
 * surface that contains buttons for every available actions.
 */
export const Actions: React.FC = () => {
  const context = useWeb3React<Web3>();
  const classes = useStyles();
  const [HarvestState, HarvestDispatch] = useReducer(HarvestData.reducer, HarvestData.DEFAULT_DATA);
  const [loader, setLoader] = useState(false);

  //todo: check if useful
  const { data: selectedPoolIndex } = usePoolInfos();
  useEffect(() => {
    // isInfinitelyApproved();
    return () => {
      setLoader(false);
    };
  }, [selectedPoolIndex]);

  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => {
    setIsModalOpen(false);
  };
  // without lazy initialization, it seems to fail
  // to dismount the previous modal (or I don't know what i'm talking about)
  // see: https://fr.reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [ModalContent, setModalContent] = useState<React.FC<ModalContentProps>>(() => DepositDialog);

  // ACTIONS
  const onClickDeposit = () => {
    setModalContent(() => DepositDialog);
    setIsModalOpen(true);
  };

  const onClickHarvest = async () => {
    setModalContent(() => HarvestDialog);
    setIsModalOpen(true);
  };

  const onClickRemove = () => {
    setModalContent(() => RemoveDialog);
    setIsModalOpen(true);
  };

  return (
    <Grid item container component={Paper} elevation={3} className={classes.root}>
      <Grid item className={classes.buttonsWindow} xs={12}>
        <Button
          startIcon={<AddCircleOutlinedIcon />}
          size="large"
          disabled={!context.active || loader}
          onClick={onClickDeposit}
          className={classes.actionButton}
          variant="contained"
          color="primary"
        >
          Deposit
        </Button>

        <Button
          startIcon={<RedeemOutlinedIcon />}
          size="large"
          disabled={!context.active || loader}
          onClick={onClickHarvest}
          className={classes.actionButton}
          variant="contained"
          color="primary"
        >
          Harvest
        </Button>

        <Button
          startIcon={<RemoveCircleOutlineOutlinedIcon />}
          size="large"
          disabled={!context.active || loader}
          className={classes.actionButton}
          onClick={onClickRemove}
          variant="contained"
          color="primary"
        >
          Remove
        </Button>

        <HarvestData.context.Provider value={{ data: HarvestState, dispatch: HarvestDispatch }}>
          <Dialog open={isModalOpen} onClose={onCloseModal}>
            <ModalContent setStateLoader={setLoader} onClose={onCloseModal} />
          </Dialog>
        </HarvestData.context.Provider>
      </Grid>
      <Grid item xs={12}>
        <Fade in={loader}>
          <LinearProgress variant="indeterminate" />
        </Fade>
      </Grid>
    </Grid>
  );
};

export default Actions;
