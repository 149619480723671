import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1),
            width: "100%",
            border: "none",
            // alignSelf: "center",
        },
        title: {
            textAlign: "center",
            fontSize: 14,
            fontFamily: "Roboto",
            textTransform: "uppercase",
        },
        paperBalance: {
            display: "flex",
            margin: theme.spacing(1),
        },
        balanceValue: {
            margin: theme.spacing(1),
            fontFamily: "Roboto",
            alignSelf: "center",
            justifySelf: "start",
            flexGrow: 1,
        },
        buyClaimable: {
            display: "flex",
            flexGrow: 0,
        },
        smallAvatar: {
            marginTop: 10,
            marginLeft: 10,
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
    })
);
