/**
 * Defines a list of all available convex pools.
 * they can be selected to change the current pool (see {@link Store/PoolInfos})
 *
 * @module PoolSelector
 */

import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import usePoolInfos from "../../store/PoolInfos";
import { dataBase as activePoolList } from "../../constants/strategyDataBase";

import useStyles from "./styles";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import HelpButton from "../HelpButton";

/**
 * Provides information to the user about the Pool selector.
 * 
 * @returns a {@link https://material-ui.com/components/buttons/ | Button}
 * associated with a {@link https://material-ui.com/components/dialogs/ | Dialog}
 * providing informations.
 */
const UserInfo: React.FC = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HelpButton
        onClick={() => {
          setIsModalOpen(true);
        }}
        style={{ transform: "scale(0.9)", margin: -4, marginLeft: -2 }}
      />
      <Dialog className={classes.Dialog} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle style={{ textAlign: "center" }}>Pool Selector</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The <b>bar</b> shows all the available pools you can invest in through our Dapp.
            <br />
            Clicking on one of the pool will change the shown statistics below (token balances and CRV / CVX amount to harvest).
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

/**
 * Generates the 'PoolSelector' module, which allow the user to select the Convex Pool
 * it wants to invest in.
 *
 * @returns a {@link https://material-ui.com/components/paper/ | Paper} containing
 * a {@link https://material-ui.com/components/app-bar | App Bar} for the Pool selection.
 */
export const PoolSelector: React.FC = () => {
  const classes = useStyles();
  const { data: selectedPoolIndex, dispatch } = usePoolInfos();

  return (
    <div className={classes.root}>
      <Paper className={classes.Panel}>
        <Typography className={classes.PanelText}>
          Selected Pool: <strong>{activePoolList[selectedPoolIndex].name.toUpperCase()}</strong>
        </Typography>
        <UserInfo />
      </Paper>
      <AppBar position="static" color="default" className={classes.AppBar}>
        <Tabs
          value={selectedPoolIndex}
          onChange={(event, value) => dispatch({ type: "update", payload: value })}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          allowScrollButtonsMobile>
          {activePoolList.map((strat, index) => (
            <Tab
              key={index}
              label={strat.name}
              icon={<Avatar alt="test avatar" src={"https://www.convexfinance.com/static/icons/svg/crypto-icons-stack.svg#" + strat.name} />}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default PoolSelector;
