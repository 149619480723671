/**
 * Defines helper functions for tokenInfos & database interactions.
 *
 * @module Utils/tokenInfos
 */

import tokenInfos from "../constants/tokenInfos";
import dataBase from "../constants/strategyDataBase";

/**
 * Retrieve Convex strategy with an index from the strategy database
 * @param index index of strat to be retrieved
 * @returns
 */
export const getDBItem = (index: number) => {
    if (index >= dataBase.length || index < 0) {
        throw new Error("bad index for accessing DataBase.");
    }

    return dataBase[index];
};

/**
 * Return the token choosen to be reinvested in Curve pools
 * @param poolIndex index of the strategy
 * @returns The token informations
 */
export const getReinvestedTokenByPool = (poolIndex: number) => {
    const pool = getDBItem(poolIndex);
    return pool.coinsTab[pool.indexCoinToReinvestOn];
};

// 
/**
 * Given a Convex strategy, provide the corresponding [[Constants/tokenInfo]] instances
 * for each token involved.
 *
 * @todo memoization !
 * @param poolIndex index of the strategy
 * @param isInTestMode see [[TestMode]].
 * @returns an array of TokenInfo instances.
 */
export const getPoolTokenInfos = (poolIndex: number, isInTestMode: boolean) => {
    const currentPoolInfos = getDBItem(poolIndex);
    // const result: TokenInfos[] = [];
    // result.push(tokenInfos.find((token) => token.symbol === "ETH")!);
    // result.push(tokenInfos.find((token) => token.symbol === "WETH")!);
    const result = tokenInfos.slice(0, isInTestMode ? 2 : 1);

    currentPoolInfos.coinsTab.map((coin) => {
        const token = tokenInfos.find((token) => token.address === coin.addr);
        if (token === undefined) {
            // throw new Error("Can't find one of the token pool in tokenInfos.");
            console.log('no token "' + coin.name + '" found.');
            return;
        }
        result.push(token);
    });
    return result;
};

export default getPoolTokenInfos;
