import { Theme, adaptV4Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";

export const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                main: "#9856B4",
            },
            secondary: {
                main: "#FFFFFF",
            },
        },
    })
);

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1,
            fontFamily: "Atkinson Hyperlegible",
        },
        app: {
            marginTop: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        addressAccount: {
            // textTransform: "uppercase",
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
        mainContent: {
            justifyContent: "center",
        },
        balances: {},
        metamaskButton: {
            alignSelf: "center",
            flexDirection: "row-reverse",
            justifyContent: "space-around",
        },
        buttonsWindow: {
            display: "flex",
            justifyContent: "space-around",
            width: "67%",
            marginTop: theme.spacing(1),
        },
        actionButton: {
            margin: theme.spacing(2),
        },
        boxBalance: {
            backgroundColor: "red",
        },
        statistics: {
            marginTop: theme.spacing(-2),
        },
        boxExplain: {
            margin: theme.spacing(1),
            textAlign: "center",
            fontFamily: "Atkinson Hyperlegible",
            font: "2em",
        },
        textExplain: {
            fontFamily: "Atkinson Hyperlegible",
            font: "2em",
        },
        PoolSelector: {
            marginTop: theme.spacing(2),
        },
    })
);
