/**
 * Defines various Typescript-related functions.
 *
 * @module Utils/typeUtils
 */

/**
 * Check if an object X has a property Y.
 * @link https://fettblog.eu/typescript-hasownproperty/
 * @param obj An instance of the object.
 * @param prop The property we want to test.
 * @returns True if the object X has the property Y, false otherwise.
 */
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return obj.hasOwnProperty(prop);
}

export default hasOwnProperty;
