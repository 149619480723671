/**
 * Defines all types, data and hook relative to the HarvestData Store
 * (see {@link Interfaces/Store}).
 *
 * This Store manage some shared data among the different components
 * involved in [[Actions/Harvest]].
 * @module Store/HarvestData
 */

import { createContext, useContext } from "react";

import DataStore, { IAction, IReducer } from "../interfaces/Store";

import { BestSwapRates, defaultSwapRateData } from "../interfaces/DEXes";

type Data = {
    swapData: BestSwapRates | undefined;
    CRVToSwap: string;
    CVXToSwap: string;
    CRVAllowance: string;
    CVXAllowance: string;
    inWalletCRV: string;
    inWalletCVX: string;
};

type ActionType = keyof Data;
type Payload = Data[ActionType];
type Action = IAction<ActionType, Payload>;

type Store = DataStore<Data, Action>;

/**
 * Possible actions:
 * - "data": Update the Swap Rate data.
 * - "[CRV|CVX]ToSwap": Update the corresponding token amount to swap.
 * - "[CRV|CVX]Allowance": Update the corresponding token allowance.
 * - "inWallet[CRV|CVX]": update the in-wallet token inclusion status.
 *
 * @param prevState The current state
 * @param action The state update to apply
 * @returns the updated state
 */
export const reducer: IReducer<Data, Action> = (state, action) => {
    //todo: find how to make it works OR why it will never work.
    // state[action.type] === action.payload;

    switch (action.type) {
        case "swapData":
            //todo: deep comparison
            if (state.swapData !== (action.payload as BestSwapRates)) {
                return { ...state, swapData: action.payload as BestSwapRates };
            }
            break;
        case "CRVToSwap":
            if (state.CRVToSwap !== (action.payload as string)) {
                return { ...state, CRVToSwap: action.payload as string };
            }
            break;
        case "CVXToSwap":
            if (state.CVXToSwap !== (action.payload as string)) {
                return { ...state, CVXToSwap: action.payload as string };
            }
            break;
        case "CRVAllowance":
            if (state.CRVAllowance !== (action.payload as string)) {
                return { ...state, CRVAllowance: action.payload as string };
            }
            break;
        case "CVXAllowance":
            if (state.CVXAllowance !== (action.payload as string)) {
                return { ...state, CVXAllowance: action.payload as string };
            }
            break;
        case "inWalletCRV":
            if (state.inWalletCRV !== (action.payload as string)) {
                return { ...state, inWalletCRV: action.payload as string };
            }
            break;
        case "inWalletCVX":
            if (state.inWalletCVX !== (action.payload as string)) {
                return { ...state, inWalletCVX: action.payload as string };
            }
            break;
    }
    return state;
};

export const DEFAULT_DATA: Data = {
    swapData: [defaultSwapRateData, defaultSwapRateData],
    CRVToSwap: "0",
    CVXToSwap: "0",
    CRVAllowance: "0",
    CVXAllowance: "0",
    inWalletCRV: "0",
    inWalletCVX: "0",
};

export const DEFAULT_STATE: Store = {
    data: DEFAULT_DATA,
    dispatch: (action: Action) => {
        throw "HarvestData: Store.dispatch was not set.";
    },
};

/**
 * React context instance to access the data.
 * It is however recommended to use the [[useHarvestData]] hook instead.
 */
export const context = createContext<Store>(DEFAULT_STATE);

/**
 * Abstract the context use with a hook-like function.
 * @returns The current state of the store.
 */
export const useHarvestData = () => {
    const store = useContext(context);
    return store;
};

export default useHarvestData;
