/**
 * Harvest Handler
 *
 * Collect the CRV and CVX rewards from the Convex protocol,
 * and reinvest them (see {@link Actions/web3/Deposit | Deposit})
 * in the current pool.
 *
 * @module Actions/Harvest
 */

import { useState, useEffect } from "react";

// MATERIAL UI
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import { useWeb3React } from "@web3-react/core";

// WEB3
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { Transaction } from "paraswap";
import { OptimalRate } from "paraswap-core";

// LOCAL
import { DEXes, BestSwapRates } from "../../../interfaces/DEXes";
import Contract from "../../../contracts/ConvexStrategist.json";
import tokenInfos from "../../../constants/tokenInfos";
import { CONVEXPOOL as ABICONVEXPOOL, ERC20 as ABIERC20 } from "../../../constants/abi";
import CurveRegistry from "../../../contracts/ICurveRegistry.json";
import { CurveRegistry as CurveRegistryAddress } from "../../../constants/addresses";
import { getParaswapTx, swapConfig, getOneInchDataTx } from "../../../utils/helpers";
import DBItem from "../../../interfaces/DBItem";
import { getDBItem, getReinvestedTokenByPool } from "../../../utils/tokenInfos";
import usePoolInfos from "../../../store/PoolInfos";
import { toBN, ERC20ToFloat, floatToERC20, setFloatPrecision } from "../../../utils/BN";
import { DEXSelector } from "./DEXSelector";
import useStyles from "./styles";

// OTHER
import { min } from "bn.js";
import Approval from "./Approval";
import useHarvestData from "../../../store/HarvestData";
import { computeHarvest } from "../Approve";
import useInterval from "../../../hooks/useInterval";
import usePopup from "../../Popup";


// TokenInfo data
const crvInfo = tokenInfos.find(({ symbol }) => symbol === "CRV")!;
const cvxInfo = tokenInfos.find(({ symbol }) => symbol === "CVX")!;

export const harvest = async (web3: Web3, account: string, currentStrat: DBItem, swapRates: BestSwapRates) => {
  // Instanciation
  const contractAddr = process.env.REACT_APP_ADDRESS;
  if (contractAddr === undefined) {
    console.log("Error during Harvest: no smart contract address provided.");
    return false;
  }

  const instance = new web3.eth.Contract(Contract.abi as AbiItem[], contractAddr);
  const crv = new web3.eth.Contract(crvInfo.abi, crvInfo.address);
  const cvx = new web3.eth.Contract(cvxInfo.abi, cvxInfo.address);
  const tokenTo = new web3.eth.Contract(ABIERC20, currentStrat.coinsTab[currentStrat.indexCoinToReinvestOn].addr);
  const curvePooLAddress = currentStrat.curvePooL;
  const convexPooLAddress = currentStrat.convexPooL;
  const convexPool = new web3.eth.Contract(ABICONVEXPOOL as AbiItem[], convexPooLAddress);

  // Compute Harvestable
  const harvestablesAmounts = swapRates.map((elem) => toBN(elem.amountIn));
  const crvToHarvest = min(harvestablesAmounts[0], toBN(await crv.methods.allowance(account, contractAddr).call({ from: account })));
  const cvxToHarvest = min(harvestablesAmounts[1], toBN(await cvx.methods.allowance(account, contractAddr).call({ from: account })));

  const thresholdHarvest = floatToERC20("1", 18);
  if (crvToHarvest.lt(thresholdHarvest) || cvxToHarvest.lt(thresholdHarvest)) {
    window.alert("The amount you're trying to harvest is too low");
    console.log("The amount you're trying to harvest is too low");
    return false;
  }

  const buildSwapTx = async (DEXName: DEXes, tokenFromAddr: string, tokenFromAmount: string): Promise<[string /*OptimalRate | OneInchAPIObject*/, string]> => {
    // Harvest fees
    const bipsBaseValue = 10000;
    const inAmount = toBN(tokenFromAmount)
      .imuln(bipsBaseValue - swapConfig.feesInBips)
      .idivn(bipsBaseValue);

    switch (DEXName) {
      default:
      case "Paraswap": {
        const result = await getParaswapTx(
          tokenFromAddr,
          18,
          tokenTo.options.address,
          await tokenTo.methods.decimals().call(),
          inAmount.toString(),
          "Reaper",
          contractAddr
        );
        if ("message" in result[0]) {
          throw new Error("Error in getParaswapTx() rates: " + result[0].message);
        }
        if ("message" in result[1]) {
          throw new Error("Error in getParaswapTx() Tx data: " + result[1].message);
        }

        return [(result[0] as OptimalRate).srcAmount, (result[1] as Transaction).data];
      }
      case "1inch": {
        const result = await getOneInchDataTx(tokenFromAddr, tokenTo.options.address, inAmount.toString(), instance.options.address, swapConfig.maxSlippage);
        if (result[1] >= 500) {
          console.log("Error 500 1inch");
          throw new Error("An error occured while querying dex aggregators. Please retry");
        }
        return [result[0].fromTokenAmount, result[0].tx.data];
      }
    }
  };
  const curveReg = new web3.eth.Contract(CurveRegistry.abi as AbiItem[], CurveRegistryAddress);
  const curveLPToken = await curveReg.methods.get_lp_token(curvePooLAddress).call();
  const [nCoins] = await curveReg.methods.get_n_coins(curvePooLAddress).call();
  const indexInAmounts = currentStrat.indexCoinToReinvestOn;

  // const crvTxData = swapRates[0].rawTxData;
  // const cvxTxData = swapRates[1].rawTxData;
  const crvTxData = await buildSwapTx(swapRates[0].dexName, crv.options.address, crvToHarvest.toString());
  const cvxTxData = await buildSwapTx(swapRates[1].dexName, cvx.options.address, cvxToHarvest.toString());

  const params = {
    crvSwapTxData: crvTxData[1],
    cvxSwapTxData: cvxTxData[1],
    tokenTo: tokenTo.options.address,
    curvePooL: curvePooLAddress,
    convexPooL: convexPool.options.address,
    curveLPToken: curveLPToken,
    crvAmount: crvToHarvest.toString(),
    cvxAmount: cvxToHarvest.toString(),
    nCoins: nCoins.toString(),
    indexInAmounts: indexInAmounts.toString(),
    crvDex: swapRates[0].dexAddr,
    cvxDex: swapRates[1].dexAddr,
  };

  console.dir(params, { depth: null });
  console.log("crv Amount: " + ERC20ToFloat(params.crvAmount, 18));
  console.log("cvx Amount: " + ERC20ToFloat(params.cvxAmount, 18));
  const stakedBefore = web3.utils.toBN(await convexPool.methods.balanceOf(account).call());
  await instance.methods.harvestAndDeposit(params).send({ from: account });
  const stakedAfter = web3.utils.toBN(await convexPool.methods.balanceOf(account).call());
  console.log("Before : " + stakedBefore.toString());
  console.log("After  : " + stakedAfter.toString());
  console.log("Added  : " + stakedAfter.sub(stakedBefore).toString());

  return true;
};

export interface HarvestProps {
  setStateLoader: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export const HarvestDialog: React.FC<HarvestProps> = (props) => {
  const classes = useStyles();
  const context = useWeb3React<Web3>();
  const { data: selectedPoolIndex } = usePoolInfos();
  const [openPopup] = usePopup();
  const currentPool = getDBItem(selectedPoolIndex);
  const { setStateLoader, onClose } = props;
  const { data: HarvestData, dispatch } = useHarvestData();

  const [tick, setTick] = useState(0);

  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 10000);

  useEffect(() => {
    const harvestable = async () => {
      if (!context.library || !context.account || !currentPool) return;
      const harvestables = await computeHarvest(context.library, context.account, currentPool);
      const crvToHarvest = harvestables[0].toString();
      const cvxToHarvest = harvestables[1].toString();
      dispatch({ type: "CRVToSwap", payload: crvToHarvest.toString() });
      dispatch({ type: "CVXToSwap", payload: cvxToHarvest.toString() });
    };

    harvestable();
  }, [tick, selectedPoolIndex, context.account]);

  const onClickHarvest = async () => {
    setStateLoader(true);
    try {
      const result = await harvest(context.library!, context.account!, currentPool, HarvestData.swapData!);
      if (!result) {
        throw "unknown error";
      }
      openPopup("Harvest Success!", "info");
    } catch (e: any) {
      console.dir(e, {depth: null});
      openPopup("Error while Harvesting on Convex: " + e?.message ?? "Unknown", "error");
    }
    setStateLoader(false);
    onClose();
  };

  const harvestButtonText = (CRVAllowance: string, CRVHarvestable: string, CVXAllowance: string, CVXHarvestable: string) => {
    if (CRVAllowance === "..." || CRVHarvestable === "..." || CVXAllowance === "..." || CVXHarvestable === "...") {
      return "Loading";
    }
    const displayApprovedCRV = setFloatPrecision(ERC20ToFloat(CRVAllowance, 18), 3);
    const displayHarvestableCRV = setFloatPrecision(ERC20ToFloat(CRVHarvestable, 18), 3);
    const displayApprovedCVX = setFloatPrecision(ERC20ToFloat(CVXAllowance, 18), 3);
    const displayHarvestableCVX = setFloatPrecision(ERC20ToFloat(CVXHarvestable, 18), 3);

    const CRVAmount = toBN(CRVAllowance).gte(toBN(CRVHarvestable))
      ? "MAX"
      : "(" + displayApprovedCRV + " out of " + displayHarvestableCRV + ")";
    const CVXAmount = toBN(CVXAllowance).gte(toBN(CVXHarvestable))
      ? "MAX"
      : "(" + displayApprovedCVX + " out of " + displayHarvestableCVX + ")";
    return "HARVEST " + CRVAmount + " CRV AND " + CVXAmount + " CVX";
  };

  return (
    <>
      <DialogTitle className={classes.simpleDialogTitle}>Harvest Process</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The smart contract claim all available CRV and CVX from the Convex <b>{currentPool.name.toUpperCase()}</b> pool, swap them through 1inch to buy{" "}
          <b>{getReinvestedTokenByPool(selectedPoolIndex).name}</b> and reinvest them in the same pool.
        </DialogContentText>
        <DialogContentText>
          <b>Harvex charge a 0.1% fee on all harvest transactions</b>. The reward estimation will grow as time goes by between approve and execution of the transaction,
          so you may have some CRV & CVX rests after the harvest in your wallet.
        </DialogContentText>
        <DialogContentText>
          Approval transactions are required first, but you can make an infinite approve of CRV and CVX spending to save you gas for future usages. If so, you can include
          in-wallet CRV/CVX to invest them too.
        </DialogContentText>
      </DialogContent>
      <hr style={{width: "90%"}}/>
        <Approval setStateLoader={setStateLoader}/>
      <hr style={{width: "90%", marginBottom: 16}}/>

      <DEXSelector />
      <DialogActions className={classes.modalActions}>
        <Button disabled={HarvestData.swapData === undefined} onClick={onClickHarvest} color="primary" variant="contained">
          {harvestButtonText(HarvestData.CRVAllowance, HarvestData.CRVToSwap, HarvestData.CVXAllowance, HarvestData.CVXToSwap)}
        </Button>
      </DialogActions>
    </>
  );
};

export default HarvestDialog;
