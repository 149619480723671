import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        rightSectionButtons: {
            margin: theme.spacing(1),
            fontFamily: "Atkinson Hyperlegible",
            color: "white",
        },
    })
);
