/**
 * Defines the claimable rewards display, as well as
 * the CRV / CVX balances display in test mode.
 *
 * @module Claimables
 */

// WEB3
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { AbiItem } from "web3-utils";
import { ERC20 } from "../../constants/abi";

import tokenInfos from "../../constants/tokenInfos";
import { CONVEXPOOL as ABICONVEXPOOL } from "../../constants/abi";
import { getCVXMintAmount } from "../../utils/helpers";

import axios from "axios";

// MATERIAL UI
import { Grid, Typography, Avatar, Fade } from "@mui/material";
import Paper from "@mui/material/Paper";

// LOCAL
import { getDBItem } from "../../utils/tokenInfos";
import usePoolInfos from "../../store/PoolInfos";
import useTestMode from "../../store/TestMode";
import useInterval from "../../hooks/useInterval";
import { ERC20ToFloat, setFloatPrecision } from "../../utils/BN";

import useStyles from "./styles";
import BuyClaimable from "./BuyClaimable";

/**
 * computes and formats the value of a token (in USD).
 * 
 * @param TokenPrice : price of the token
 * @param tokenAmount : amount of the token
 * @returns the value of the given amount of token (in USD).
 */
function computeTokenValue(TokenPrice: string, tokenAmount: string) {
  const result = (parseFloat(TokenPrice) * parseFloat(tokenAmount)).toFixed(2);
  return result === "NaN" ? "..." : "(" + result + "$)";
}

/**
 * Generates the 'Claimable' module, which shows informations about the user's current position
 * in selected pool, such as the amount of staked liquidity, and the available CRV & CVX to claim.
 * 
 * @returns a {@link https://material-ui.com/components/grid/ | Grid}
 * displaying infos about claimable tokens.
 */
export const ClaimableRewards: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const context = useWeb3React<Web3>();
  const { library: web3, account } = context;
  const [crvToHarvest, setCrvToHarvest] = useState<string>("");
  const [cvxToHarvest, setCvxToHarvest] = useState<string>("");
  const { data: isInTestMode } = useTestMode();
  const { data: selectedPoolIndex } = usePoolInfos();
  const currentPooL = getDBItem(selectedPoolIndex);
  const [currentStake, setCurrentStake] = useState<string>("");
  const [currentLPName, setCurrentLPName] = useState<string>("");

  const [tick, setTick] = useState(0);
  const [crvPrice, setCrvPrice] = useState<string>("");
  const [cvxPrice, setCvxPrice] = useState<string>("");
  const crvInfo = tokenInfos.find((token) => token.symbol === "CRV");
  const cvxInfo = tokenInfos.find((token) => token.symbol === "CVX");
  if (!crvInfo || !cvxInfo) throw new Error("no Token infos found.");

  /**
   * Compute claimable amount of CRV / CVX
   * Get value from Coingecko API
   * Compute allowance and decide wether or not approvals is required
   */
  const claimable = async () => {
    if (!web3) return;
    const crv = new web3.eth.Contract(crvInfo.abi, crvInfo.address);
    const cvx = new web3.eth.Contract(cvxInfo.abi, cvxInfo.address);
    const convexPool = new web3.eth.Contract(ABICONVEXPOOL as AbiItem[], currentPooL.convexPooL);
    const crvToHarvest = web3.utils.toBN(await convexPool.methods.earned(account).call());
    const cvxTotalSupply = web3.utils.toBN(await cvx.methods.totalSupply().call());

    // NB: web3.utils.isBN interface is incorrect, so we force type to any...
    // see https://web3js.readthedocs.io/en/v1.3.6/web3-utils.html
    if (web3.utils.isBN(crvToHarvest as any)) {
      const cvxToHarvest = getCVXMintAmount(crvToHarvest, cvxTotalSupply);
      const currentStake = web3.utils.toBN(await convexPool.methods.balanceOf(account).call());
      const decimals = 18;// CRV or CVX

      // Coingecko
      const urlCrv = "https://api.coingecko.com/api/v3/simple/price?ids=curve-dao-token&vs_currencies=usd";
      const urlCvx = "https://api.coingecko.com/api/v3/simple/price?ids=convex-finance&vs_currencies=usd";
      const cgPriceCrv = await axios.get(urlCrv);
      const cgPriceCvx = await axios.get(urlCvx);

      setCrvPrice(cgPriceCrv.data["curve-dao-token"].usd);
      setCvxPrice(cgPriceCvx.data["convex-finance"].usd);

      setCrvToHarvest(ERC20ToFloat(crvToHarvest, decimals));
      // console.log("---->" + crvToHarvest.div(decimals).toString());

      setCvxToHarvest(ERC20ToFloat(cvxToHarvest, decimals));
      // console.log("---->" + cvxToHarvest.div(decimals).toString());

      const tmpCurrentStake = ERC20ToFloat(currentStake, decimals);
      setCurrentStake(tmpCurrentStake);
      const lpTokenInstance = new web3.eth.Contract(ERC20, currentPooL.lpToken);
      setCurrentLPName(await lpTokenInstance.methods.symbol().call());
    }
  };

  // this interval will update tick every 5 seconds
  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 5000);

  useEffect(() => {
    claimable();
  }, [selectedPoolIndex, web3, tick]);

  return (
    <Grid item component={Paper} variant="outlined" className={classes.container}>
      <Typography className={classes.title} color="textPrimary">
        <strong> POOL INFORMATIONS </strong>
      </Typography>
      <Paper elevation={3} className={classes.paperBalance}>
        <Typography className={classes.balanceValue}>
          Current Convex Stake : {setFloatPrecision(currentStake, 3)} {currentLPName}
        </Typography>
      </Paper>
      <Paper elevation={3} className={classes.paperBalance}>
        <Avatar className={classes.smallAvatar} alt="Curve DAO Token" src={"https://www.convexfinance.com/static/icons/svg/crypto-icons-stack.svg#crv"} />
        <Typography className={classes.balanceValue}>
          {"Claimable CRV = " + setFloatPrecision(crvToHarvest, 3)}
          {crvToHarvest !== "0" ? " " + computeTokenValue(crvPrice, crvToHarvest) : ""}
        </Typography>
        <Fade in={isInTestMode}>
          <BuyClaimable name="CRV" style={{display: "flex"}}/>
        </Fade>
      </Paper>
      <Paper elevation={3} className={classes.paperBalance}>
        <Avatar className={classes.smallAvatar} alt="Convex Token" src={"https://www.convexfinance.com/static/icons/svg/crypto-icons-stack.svg#cvx"} />
        <Typography className={classes.balanceValue}>
          {"Claimable CVX = " + setFloatPrecision(cvxToHarvest, 3)}
          {cvxToHarvest !== "0" ? " " + computeTokenValue(cvxPrice, cvxToHarvest) : ""}
        </Typography>
        <Fade in={isInTestMode}>
          <BuyClaimable name="CVX" style={{display: "flex", flexGrow: 0}}/>
        </Fade>
        </Paper>
    </Grid>
  );
};

export default ClaimableRewards;
