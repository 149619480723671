/**
 * Defines all types, data and hook relative to the popupData Store
 * (see {@link Interfaces/Store}).
 *
 * This Store manage the state of the [[Popup]] component
 * @module Store/PopupData
 */

import React, { createContext, useContext } from "react";

import DataStore, { IAction, IReducer } from "../interfaces/Store";
import { AlertColor as Severity } from '@mui/material/Alert';

type Data = {
    isOpen: boolean;
    message: string;
    severity: Severity;
};

type Payload = boolean | string | Severity;

type ActionType = keyof Data;//"isOpen" | "message" | "severity";
type Action = IAction<ActionType, Payload>;

type Store = DataStore<Data, Action>;

/**
 * Possible actions:
 * - "open": change the state to open or closed (true or false).
 * - "message": set the custom message.
 * - "severity": define the severity of the message.
 *
 * @param prevState The current state
 * @param action The state update to apply
 * @returns the updated state
 */
export const reducer: IReducer<Data, Action> = (state, action) => {
    switch (action.type) {
        case "isOpen":
            if (state.isOpen !== (action.payload as boolean)) {
                return { ...state, isOpen: action.payload as boolean };
            }
            break;
        case "message":
            if (state.message !== (action.payload as string)) {
                return { ...state, message: action.payload as string };
            }
            break;
        case "severity":
            if (state.severity !== (action.payload as Severity)) {
                return { ...state, severity: action.payload as Severity };
            }
            break;
    }

    return state;
};

export const DEFAULT_DATA: Data = {
    isOpen: false,
    message: "",
    severity: "info",
};

export const DEFAULT_STATE: Store = {
    data: DEFAULT_DATA,
    dispatch: (action: Action) => {
        throw "popupData: Store.dispatch was not set.";
    },
};

/**
 * React context instance to access the data.
 * It is however recommended to use the [[usePopupData]] hook instead.
 */
export const context = createContext<Store>(DEFAULT_STATE);

/**
 * Abstract the context use with a hook-like function.
 * @returns The current state of the store.
 */
export const usePopupData = () => {
    const store = useContext(context);
    return store;
};

export default usePopupData;
