/**
 * Utility function for {@link https://github.com/NoahZinsmeister/web3-react
 * | web3-react}.
 *
 * @module Utils/getLibrary
 */

import Web3 from "web3";

export default function getLibrary(provider: any): Web3 {
    const library = new Web3(provider);
    return library;
}
