/**
 * Defines functions that deals with {@link https://github.com/indutny/bn.js/ | BN} instances
 * and ERC20 token amount values.
 * 
 * @module Utils/BN
 */

import Web3 from "web3";

/**
 * Convert a number or a string into a [[BN]].
 * 
 * @param value the number (or its string representation) to be converted.
 * @returns the value as a BN instance.
 */
export const toBN = Web3.utils.toBN;
const tmpBN = Web3.utils.toBN(0);

/**
 * BN.js (Web3 version for compatibility)
 */
export type BN = typeof tmpBN;


const popAt = (str: string, index: number) => {
  let result = str.substring(0, index);
  if (index < str.length) {
    result += str.substring(index + 1);
  }

  return result;
}

/**
 * Turns a string representation of a float to its ERC20 value (integer).
 * (ex: "11.2" with decimals == 2 returns "1120")
 * 
 * @param num the value, in decimal format. It must respect the following format: /^\d*(.\d+)?$/
 * @param decimals the number of decimal allowed by the token, as in the ERC20 function 'decimals()'.
 * @returns the value, as a {@link https://github.com/indutny/bn.js/ | BN}.
 */
export const floatToERC20 = (num: string, decimals: number): BN => {
  let padding = "0".repeat(decimals);
  const paddedNum = num + padding;

  const dotPosition = paddedNum.indexOf('.');
  if (dotPosition === -1) {
    // there is no decimals ; the number is now properly formated.
    return Web3.utils.toBN(paddedNum);
  }

  const result = popAt(paddedNum, dotPosition);
  return Web3.utils.toBN(result.substring(0, dotPosition + decimals));
};

/**
 * Turns a ERC20 value to its 'user-friendly' string representation (float).
 * (ex: "1120" with decimals == 2 returns "11.2")
 * 
 * @param num the value, in ERC20 format.
 * @param decimals the number of decimal allowed by the token, as in the ERC20 function 'decimals()'.
 * @returns the value, as a string.
 */
export const ERC20ToFloat = (num: string | BN, decimals: number): string => {
  if (typeof num === "string") {
    num = Web3.utils.toBN(num);
  }
  const base = Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals));

  // fractional part
  let fraction = num.mod(base).abs().toString();
  while (fraction.length < decimals) {
    fraction = '0' + fraction;
  }
  // trim the trailing '0's
  fraction = fraction.match(/^([0-9]*[1-9]|0)(0*)/)![1];

  // integer part
  let whole = num.div(base).toString();

  const result = whole.toString() + (fraction === '0' ? '' : '.' + fraction);
  return result;
};

/**
 * Set the number of decimals digits.
 *
 * @param num The number to format.
 * @param precision The amount of digit we want.
 * @returns the formatted number.
 */
export const setFloatPrecision = (num: string, precision: number) => {
  const decomposed = num.split('.');
  if (decomposed.length !== 2) {
    return num;
  }

  if (decomposed[1].length >= precision) {
    return num.substring(0, decomposed[0].length + precision + 1);
  }

  return num/*+ '0'.repeat(precision - decomposed[1].length)*/;
}