/**
 * Provide the getMoreHarvest function, which allow to perform a harvest
 * without providing liquidity in a Convex Pool.
 *
 * @module Claimables/getMoreHarvest
 */

import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useWeb3React } from "@web3-react/core";
import { Typography, Button, Fade, CircularProgress } from "@mui/material";

import tokenInfos from "../../constants/tokenInfos";
import { RewardTokens } from "../../interfaces/DEXes";
import { ERC20ToFloat, setFloatPrecision } from "../../utils/BN";

// import { TokenTransferProxy, AugustusSwaperV5 } from "../../constants/addresses";
import { oneInchSwaperApprove, oneInchSwaper } from "../../constants/addresses";
import { getOneInchDataTx, getParaswapTx, swapConfig } from "../../utils/helpers";

import useInterval from "../../hooks/useInterval";
import usePopup from "../Popup";
import hasOwnProperty from "../../utils/typeUtils";

/**
 * Swap WETH for CRV / CVX.
 *
 * @param name the token's name to buy
 * @param web3Context web3 instance
 * @param account the currently used wallet address
 * @returns true if the operation succeed.
 */
const getMoreHarvest = async (name: RewardTokens, web3: Web3, account: string) => {
  const wethInfo = tokenInfos.find((token) => token.symbol === "WETH");
  const tokenToInfo = tokenInfos.find(({ symbol }) => symbol === name);
  if (!tokenToInfo || !wethInfo) throw new Error("Tokens not found");
  const weth = new web3.eth.Contract(wethInfo.abi as AbiItem[], wethInfo.address);
  const decimals = 18; //crv or cvx
  const amountIn = web3.utils.toBN(web3.utils.toWei("0.1"));
  const [, rawTx] = await getParaswapTx(
    wethInfo.address,
    decimals,
    tokenToInfo.address,
    decimals,
    amountIn.toString(),
    "Reaper",
    account,
    swapConfig.rateOptionsParaswap,
    swapConfig.buildOptionsParaswap
  );
  if (hasOwnProperty(rawTx, "message")) {
    throw Error("getMoreHarvest: Error while swapping.");
  }

  // const result = await getOneInchDataTx(wethInfo.address, tokenToInfo.address, amountIn.toString(), account, swapConfig.maxSlippage);
  // if (result[1] >= 500) {
  //   console.log("Error 500 1inch: " + result[1].toString());
  //   throw new Error("An error occured while querying dex aggregators. Please retry");
  // }
  // const rawTx = result[0].tx;
  await weth.methods.approve(oneInchSwaperApprove, amountIn).send({ from: account });
  const txReceipt = await web3.eth.sendTransaction({
    from: account,
    to: oneInchSwaper,
    data: rawTx.data,
  });
  return txReceipt.status;
};

export type BuyClaimableProps = {
  name: RewardTokens;
  style?: React.CSSProperties;
};

/**
 * Render a Button that allow the user to buy either CRV or CVX.
 * This module also display the user balance for the corresponding token.
 * It will only be displayed if test mode is ON.
 *
 * @param props name: "CRV" or "CVX".
 * @returns the corresponding JSX element.
 */
export const BuyClaimable = React.forwardRef<HTMLDivElement, BuyClaimableProps>((props, ref) => {
  const context = useWeb3React<Web3>();
  const { library: web3, account } = context;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokenInWallet, setTokenInWallet] = useState<string>("");
  const [openPopup] = usePopup();
  const { name, style } = props;

  // this interval will update tick every 5 seconds
  const [tick, setTick] = useState(0);
  useInterval(() => {
    setTick((tick + 1) % 100);
  }, 5000);

  useEffect(() => {
    if (!web3) return;

    const tokenInfo = tokenInfos.find((token) => token.symbol === name);
    if (!tokenInfo) throw new Error("no Token infos found.");

    const contract = new web3.eth.Contract(tokenInfo.abi, tokenInfo.address);
    contract.methods
      .balanceOf(account)
      .call()
      .then((balance: string) => {
        setTokenInWallet(ERC20ToFloat(balance, 18));
      });
  }, [tick, web3, account]);

  const buyToken = async () => {
    setIsLoading(true);
    try {
      await getMoreHarvest(name, web3!, account!);
      openPopup("Successfully bought " + name, "info");
    } catch (e: any) {
      openPopup(name + ": " + (e?.message ?? "Unknown error during the purchase."), "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={style} ref={ref}>
      <Typography style={{ alignSelf: "center", justifySelf: "start" }}>In Wallet : {setFloatPrecision(tokenInWallet, 3)}</Typography>
      <Fade in={isLoading}>
        <CircularProgress variant="indeterminate" style={{ alignSelf: "center", padding: 4 }} />
      </Fade>
      <Button disabled={!web3 || !account || isLoading} variant="contained" size="small" onClick={buyToken} style={{ margin: 8 }} color="primary">
        ADD
      </Button>
    </div>
  );
});

export default BuyClaimable;
