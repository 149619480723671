/**
 * Provides a ETH > WETH > ERC20 tokens swap function.
 *
 * @module Balances/SwapETHForTokens
 */

// WEB3
import Web3 from "web3";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";

import { TokenTransferProxy, oneInchSwaperApprove } from "../../constants/addresses";
import { ERC20 } from "../../constants/abi";
import tokenInfos from "../../constants/tokenInfos";
import { getParaswapTx, swapConfig, getOneInchDataTx } from "../../utils/helpers";
import { getReinvestedTokenByPool } from "../../utils/tokenInfos";
import { hasOwnProperty } from "../../utils/typeUtils";

/**
 * Swap a fixed amount of WETH for any ERC20 token available on the used DEX Aggregator.
 * It is mainly used to test the front-end features.
 *
 * @param name name of the token to be swap
 * @param web3Context web3 instance
 * @param poolIndex current selected pool used to find which token need to be get (todo: why?)
 * @returns
 */
export const swapETHForTokens = async (name: String, web3Context: Web3ReactContextInterface<Web3>, poolIndex: number) => {
    const { library: web3, account } = web3Context;
    if (web3 === undefined || account === undefined || account === null) {
        return false;
    }
    const wethInfo = tokenInfos.find((token) => token.symbol === "WETH");
    if (wethInfo === undefined) {
        return false;
    }

    const weth = new web3.eth.Contract(wethInfo.abi, wethInfo.address);
    const WETH_AMOUNT_TO_SWAP = "100";
    const amountIn = web3.utils.toBN(web3.utils.toWei(WETH_AMOUNT_TO_SWAP));
    let txReceipt;
    switch (name) {
        case "WETH":
            txReceipt = await weth.methods.deposit().send({ value: amountIn, from: account });
            return txReceipt.status as boolean;
        default:
            const tokenToInfo = getReinvestedTokenByPool(poolIndex);
            const tokenFrom = weth;
            const tokenTo = new web3.eth.Contract(ERC20, tokenToInfo.addr);
            const tokenFromDecimals = await tokenFrom.methods.decimals().call();
            const tokenToDecimals = await tokenTo.methods.decimals().call();
            const referrer = "Reaper";
            const signer = account;

            switch (swapConfig.dexAggregator) {
                case "Paraswap":
                    const [, PSTxData] = await getParaswapTx(
                        tokenFrom.options.address,
                        tokenFromDecimals,
                        tokenTo.options.address,
                        tokenToDecimals,
                        amountIn.toString(),
                        referrer,
                        signer,
                        swapConfig.rateOptionsParaswap,
                        swapConfig.buildOptionsParaswap
                    );
                    if (hasOwnProperty(PSTxData, "message")) {
                        console.log("error while building Paraswap Tx: " + PSTxData.message);
                        return false;
                    }
                    await tokenFrom.methods.approve(TokenTransferProxy, amountIn).send({ from: account });
                    txReceipt = await web3.eth.sendTransaction(PSTxData);
                    return txReceipt.status;
                case "1inch":
                    await tokenFrom.methods.approve(oneInchSwaperApprove, amountIn).send({ from: account });
                    const [oneInchTxData] = await getOneInchDataTx(wethInfo.address, tokenToInfo.addr, amountIn.toString(), signer, swapConfig.maxSlippage);
                    const estimatedGas = await web3.eth.estimateGas({
                        from: account,
                        to: oneInchSwaperApprove,
                        data: oneInchTxData.tx.data,
                    });
                    txReceipt = await web3.eth.sendTransaction({
                        from: account,
                        to: oneInchSwaperApprove,
                        data: oneInchTxData.tx.data,
                        gas: estimatedGas,
                    });
                    return txReceipt.status;
            }
    }
};

export default swapETHForTokens;
