/**
 * Defines a clickable button that is used for providing informations to the user.
 *
 * @module HelpButton
 */

import React from "react";
import useStyles from "./styles";

import { IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

type HelpButtonProps = {
  onClick: () => void;
  style?: React.CSSProperties;
};

/**
 * Generates the 'HelpButton' module, which provide a "help"
 * {@link https://material-ui.com/components/buttons/#icon-button | clickable button}.
 * the click behavior is defined in the module props.
 *
 * @param props the module's properties.
 * @returns
 */
export const HelpButton: React.FC<HelpButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.default} style={props.style} size="small" onClick={props.onClick}>
      <HelpIcon />
    </IconButton>
  );
};

export default HelpButton;
