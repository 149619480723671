/**
 * Defines the supported chains for
 * {@link https://github.com/NoahZinsmeister/web3-react | web3-react}.
 * 
 * @module Constants/chains
 */

export enum SupportedChainId {
    MAINNET = 1,
    GANACHE = 539,
}
export const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
    [SupportedChainId.MAINNET]: "Mainnet",
    [SupportedChainId.GANACHE]: "Ganache",
};
