/**
 * Defines the Header band of the website.
 *
 * @module App/Header
 */

import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MetamaskButton from "../MetamaskButton";
import TestModeButton from "../TestModeButton";
import HelpButton from "../HelpButton";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      alignItems: "center",
      fontFamily: "Atkinson Hyperlegible",
    },
  })
);

/**
 * Provides information to the user about what this website is.
 * 
 * @returns a {@link https://material-ui.com/components/buttons/ | Button}
 * associated with a {@link https://material-ui.com/components/dialogs/ | Dialog}
 * providing informations.
 */
const UserInfo: React.FC = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HelpButton
        onClick={() => {
          setIsModalOpen(true);
        }}
        style={{ transform: "scale(1.42)", marginLeft: 12, marginRight: 12, color: "white" }}
      />
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle style={{ textAlign: "center" }}>What is Harvex.xyz?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This website is a life simplier for Convex Liquidity Providers. It allows anyone to use our home-made
            Smart Contract to deposit liquidity & compound interests in the supported Convex pools, while saving time and money!
          </DialogContentText>
          <DialogContentText>
            By reinvesting the CRV & CVX rewards immediately on harvest, you will be able to save a lot of gas while increasing your positions in the investment pool.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

/**
 * Generates the 'Header' module, which displays information about the website,
 * as well as the [[MetamaskButton]] and the [[TestModeButton]].
 * @returns 
 */
export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar style={{ background: "#9856B4" }} position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Convex Harvester. One-click your harvest process & Save gas
            <UserInfo />
          </Typography>
          {window.location.search === "?debug" && <TestModeButton />}
          <MetamaskButton />
        </Toolbar>
      </AppBar>
    </div>
  );
}
