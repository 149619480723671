/**
 * Encompass all the components structure of the web page,
 * and initialize different {@link https://reactjs.org/docs/context.html
 *  | React context's providers}.
 * @module App
 */
import React, { useReducer } from "react";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";

import Container from "@mui/material/Container";
import { Box, Grid, Typography, Paper, CssBaseline } from "@mui/material";

import getLibrary from "../../utils/getLibrary";
import { useEagerConnect, useInactiveListener } from "../../hooks/web3";

import BalancesInfos from "../Balances";
import ClaimablesInfos from "../Claimables";
import Statistics from "../Statistics";
import Actions from "../Actions";

import * as PoolInfo from "../../store/PoolInfos";
import * as TestMode from "../../store/TestMode";
import * as PopupData from "../../store/PopupData";
import useStyles, { theme } from "./styles";
import { ThemeProvider } from "@mui/material/styles";
import {Theme, StyledEngineProvider} from "@mui/material/styles";

import Header from "./Header";
import PoolSelector from "../PoolSelector";
import { Popup } from "../Popup";

// Additional application configuration
import config from "../../utils/config";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


config();

// wrap our App with some context providers
const App: React.FC = () => {
  const [PUState, PUDispatch] = useReducer(PopupData.reducer, PopupData.DEFAULT_DATA);
  const [PIState, PIDispatch] = useReducer(PoolInfo.reducer, PoolInfo.DEFAULT_DATA);
  const [TMState, TMDispatch] = useReducer(TestMode.reducer, TestMode.DEFAULT_DATA);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Web3ReactProvider getLibrary={getLibrary}>
          <TestMode.context.Provider value={{ data: TMState, dispatch: TMDispatch }}>
            <PoolInfo.context.Provider value={{ data: PIState, dispatch: PIDispatch }}>
            <PopupData.context.Provider value={{ data: PUState, dispatch: PUDispatch }}>
              <_App />
              </PopupData.context.Provider>
            </PoolInfo.context.Provider>
          </TestMode.context.Provider>
        </Web3ReactProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

const _App: React.FC = () => {
  const { account } = useWeb3React();
  const classes = useStyles();

  // handle logic to eagerly connect to the injected ethereum provider,
  // if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events
  // on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);

  const contractAddress =  process.env.REACT_APP_ADDRESS ?? "..."

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Header />
        <div className={classes.app}>
          <Grid container className={classes.mainContent} component={Paper} spacing={2}>
            <Grid item component={Paper} className={classes.boxExplain}>
              <Typography className={classes.boxExplain} color="initial">
                Connected with account : <strong>{account ?? "..."}</strong>
              </Typography>
              <Typography className={classes.boxExplain} color="initial">
                Smart Contract address : <strong>{contractAddress}</strong>
              </Typography>
            </Grid>
            <Grid item container className={classes.PoolSelector} xs={10}>
              <PoolSelector />
            </Grid>
            <Grid container item className={classes.balances} xs={3}>
              <Box component={BalancesInfos} />
            </Grid>
            <Grid container item className={classes.balances} xs={7}>
              <Box component={ClaimablesInfos} />
            </Grid>
            <Grid container item className={classes.statistics} xs={7}>
              <Box component={Statistics} />
            </Grid>
            <Grid container item xs={12} style={{ placeContent: "center" }}>
              <Actions />
            </Grid>
          </Grid>
          <Popup />
        </div>
      </Container>
    </div>
  );
};