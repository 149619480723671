/**
 * TokenInfo gather all required tokens instanciated with their name, symbol, address and ABI.
 * This list is completed by the {@link Constants/strategyDataBase | Curve Database}.
 *
 * @module Constants/tokenInfos
 */

import TokenInfo from "../interfaces/TokenInfo";
import * as Addresses from "./addresses";
import * as ABI from "./abi";

const tokenInfos: TokenInfo[] = [
    { symbol: "ETH", address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", abi: [], name: "Ether" },
    {
        name: "Wrapped Ether",
        symbol: "WETH",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        abi: ABI.WETH,
    },
    { symbol: "CRV", name: "Curve DAO Token", address: "0xD533a949740bb3306d119CC777fa900bA034cd52", abi: ABI.ERC20 },
    { symbol: "CVX", name: "Convex Token", address: "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b", abi: ABI.ERC20 },
    {
        name: "Dai Stablecoin",
        symbol: "DAI",
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        abi: ABI.ERC20,
    },
    {
        name: "USD Coin",
        symbol: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        abi: ABI.ERC20,
    },
    {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        abi: ABI.ERC20,
    },
    {
        name: "Aave interest bearing DAI",
        symbol: "aDAI",
        address: "0x028171bCA77440897B824Ca71D1c56caC55b68A3",
        abi: ABI.ERC20,
    },
    {
        name: "Aave interest bearing USDC",
        symbol: "aUSDC",
        address: "0xBcca60bB61934080951369a648Fb03DF4F96263C",
        abi: ABI.ERC20,
    },
    {
        name: "Aave interest bearing USDT",
        symbol: "aUSDT",
        address: "0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811",
        abi: ABI.ERC20,
    },
    {
        name: " Ankr ETH2 Reward Bearing Certificate",
        symbol: "aETHc",
        address: "0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
        abi: ABI.ERC20,
    },
    {
        name: "iearn DAI",
        symbol: "yDAI",
        address: "0xC2cB1040220768554cf699b0d863A3cd4324ce32",
        abi: ABI.ERC20,
    },
    {
        name: "iearn USDC",
        symbol: "yUSDC",
        address: "0x26EA744E5B887E5205727f55dFBE8685e3b21951",
        abi: ABI.ERC20,
    },
    {
        name: "iearn USDT",
        symbol: "yUSDT",
        address: "0xE6354ed5bC4b393a5Aad09f21c46E101e692d447",
        abi: ABI.ERC20,
    },
    {
        name: "iearn BUSD",
        symbol: "yBUSD",
        address: "0x04bC0Ab673d88aE9dbC9DA2380cB6B79C4BCa9aE",
        abi: ABI.ERC20,
    },
    {
        name: "Compound Dai",
        symbol: "cDAI",
        address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
        abi: ABI.ERC20,
    },
    {
        name: "Compound USD Coin",
        symbol: "cUSDC",
        address: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
        abi: ABI.ERC20,
    },
    {
        name: "STASIS EURS Token",
        symbol: "EURS",
        address: "0xdB25f211AB05b1c97D595516F45794528a807ad8",
        abi: ABI.ERC20,
    },
    {
        name: "Synth sEUR",
        symbol: "sEUR",
        address: "0xD71eCFF9342A5Ced620049e616c5035F1dB98620",
        abi: ABI.ERC20,
    },
    {
        name: "Huobi BTC",
        symbol: "HBTC",
        address: "0x0316EB71485b0Ab14103307bf65a021042c6d380",
        abi: ABI.ERC20,
    },
    {
        name: "Wrapped BTC",
        symbol: "WBTC",
        address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        abi: ABI.ERC20,
    },
    {
        name: "Yearn Dai Stablecoin",
        symbol: "cyDAI",
        address: "0x8e595470Ed749b85C6F7669de83EAe304C2ec68F",
        abi: ABI.ERC20,
    },
    {
        name: "Yearn USD Coin",
        symbol: "cyUSDC",
        address: "0x76Eb2FE28b36B3ee97F3Adae0C69606eeDB2A37c",
        abi: ABI.ERC20,
    },
    {
        name: "Yearn Tether USD",
        symbol: "cyUSDT",
        address: "0x48759F220ED983dB51fA7A8C0D2AAb8f3ce4166a",
        abi: ABI.ERC20,
    },
    {
        name: "ChainLink Token",
        symbol: "LINK",
        address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
        abi: ABI.ERC20,
    },
    {
        name: "Synth sLINK",
        symbol: "sLINK",
        address: "0xbBC455cb4F1B9e4bFC4B73970d360c8f032EfEE6",
        abi: ABI.ERC20,
    },
    {
        name: "Curve (iearn fork) DAI",
        symbol: "ycDAI",
        address: "0x99d1Fa417f94dcD62BfE781a1213c092a47041Bc",
        abi: ABI.ERC20,
    },
    {
        name: "Curve (iearn fork) USDC",
        symbol: "ycUSDC",
        address: "0x9777d7E2b60bB01759D0E2f8be2095df444cb07E",
        abi: ABI.ERC20,
    },
    {
        name: "Curve (iearn fork) USDT",
        symbol: "ycUSDT",
        address: "0x1bE5d71F2dA660BFdee8012dDc58D024448A0A59",
        abi: ABI.ERC20,
    },
    {
        name: "Paxos Standard",
        symbol: "PAX",
        address: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
        abi: ABI.ERC20,
    },
    {
        name: "renBTC",
        symbol: "renBTC",
        address: "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
        abi: ABI.ERC20,
    },
    {
        name: "Wrapped BTC",
        symbol: "WBTC",
        address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        abi: ABI.ERC20,
    },
    {
        name: "Aave interest bearing DAI",
        symbol: "aDAI",
        address: "0x028171bCA77440897B824Ca71D1c56caC55b68A3",
        abi: ABI.ERC20,
    },
    {
        name: "Aave interest bearing SUSD",
        symbol: "aSUSD",
        address: "0x6C5024Cd4F8A59110119C56f8933403A539555EB",
        abi: ABI.ERC20,
    },
    {
        name: "renBTC",
        symbol: "renBTC",
        address: "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
        abi: ABI.ERC20,
    },
    {
        name: "Wrapped BTC",
        symbol: "WBTC",
        address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        abi: ABI.ERC20,
    },
    {
        name: "Synth sBTC",
        symbol: "sBTC",
        address: "0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6",
        abi: ABI.ERC20,
    },
    {
        name: "Synth sETH",
        symbol: "sETH",
        address: "0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb",
        abi: ABI.ERC20,
    },
    {
        name: "Liquid staked Ether 2.0",
        symbol: "stETH",
        address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
        abi: ABI.ERC20,
    },
    {
        name: "Dai Stablecoin",
        symbol: "DAI",
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        abi: ABI.ERC20,
    },
    {
        name: "USD Coin",
        symbol: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        abi: ABI.ERC20,
    },
    {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        abi: ABI.ERC20,
    },
    {
        name: "Synth sUSD",
        symbol: "sUSD",
        address: "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
        abi: ABI.ERC20,
    },
    {
        name: "Compound Dai",
        symbol: "cDAI",
        address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
        abi: ABI.ERC20,
    },
    {
        name: "Compound USD Coin",
        symbol: "cUSDC",
        address: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
        abi: ABI.ERC20,
    },
    {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        abi: ABI.ERC20,
    },
    {
        name: "iearn DAI",
        symbol: "yDAI",
        address: "0x16de59092dAE5CcF4A1E6439D611fd0653f0Bd01",
        abi: ABI.ERC20,
    },
    {
        name: "iearn USDC",
        symbol: "yUSDC",
        address: "0xd6aD7a6750A7593E092a9B218d66C0A814a3436e",
        abi: ABI.ERC20,
    },
    {
        name: "iearn USDT",
        symbol: "yUSDT",
        address: "0x83f798e925BcD4017Eb265844FDDAbb448f1707D",
        abi: ABI.ERC20,
    },
    {
        name: "iearn TUSD",
        symbol: "yTUSD",
        address: "0x73a052500105205d34Daf004eAb301916DA8190f",
        abi: ABI.ERC20,
    },
    {
        name: "DefiDollar",
        symbol: "DUSD",
        address: "0x5BC25f649fc4e26069dDF4cF4010F9f706c23831",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Gemini dollar",
        symbol: "GUSD",
        address: "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "HUSD",
        symbol: "HUSD",
        address: "0xdF574c24545E5FfEcb9a659c229253D4111d87e1",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "LINKUSD",
        symbol: "LINKUSD",
        address: "0x0E2EC54fC0B509F445631Bf4b91AB8168230C752",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "mStable USD",
        symbol: "mUSD",
        address: "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Reserve",
        symbol: "RSV",
        address: "0x196f4727526eA7FB1e17b2071B3d8eAA38486988",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "USDK",
        symbol: "USDK",
        address: "0x1c48f86ae57291F7686349F12601910BD8D470bb",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Neutrino USD",
        symbol: "USDN",
        address: "0x674C6Ad92Fd080e4004b2312b45f796a192D27a0",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "USDP Stablecoin",
        symbol: "USDP",
        address: "0x1456688345527bE1f37E9e627DA0837D6f08C925",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Wrapped UST Token",
        symbol: "UST",
        address: "0xa47c8bf37f92aBed4A126BDA807A7b7498661acD",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Binance Wrapped BTC",
        symbol: "BBTC",
        address: "0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi renBTC/wBTC/sBTC",
        symbol: "crvRenWSBTC",
        address: "0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3",
        abi: ABI.ERC20,
    },
    {
        name: "BoringDAO BTC",
        symbol: "oBTC",
        address: "0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi renBTC/wBTC/sBTC",
        symbol: "crvRenWSBTC",
        address: "0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3",
        abi: ABI.ERC20,
    },
    {
        name: "pTokens BTC",
        symbol: "pBTC",
        address: "0x5228a22e72ccC52d415EcFd199F99D0665E7733b",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi renBTC/wBTC/sBTC",
        symbol: "crvRenWSBTC",
        address: "0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3",
        abi: ABI.ERC20,
    },
    {
        name: "tBTC",
        symbol: "TBTC",
        address: "0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi renBTC/wBTC/sBTC",
        symbol: "crvRenWSBTC",
        address: "0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3",
        abi: ABI.ERC20,
    },
    {
        name: "TrueUSD",
        symbol: "TUSD",
        address: "0x0000000000085d4780B73119b644AE5ecd22b376",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "LUSD Stablecoin",
        symbol: "LUSD",
        address: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Frax",
        symbol: "FRAX",
        address: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Binance USD",
        symbol: "BUSD",
        address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "StaFi",
        symbol: "rETH",
        address: "0x9559Aaa82d9649C7A7b220E7c461d2E74c9a3593",
        abi: ABI.ERC20,
    },
    {
        name: "Alchemix USD",
        symbol: "alUSD",
        address: "0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9",
        abi: ABI.ERC20,
    },
    {
        name: "Curve.fi DAI/USDC/USDT",
        symbol: "3Crv",
        address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
        abi: ABI.ERC20,
    },
    {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        abi: ABI.ERC20,
    },
    {
        name: "Wrapped BTC",
        symbol: "WBTC",
        address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        abi: ABI.ERC20,
    },
    {
        name: "Euro Tether",
        symbol: "EURT",
        address: "0xC581b735A1688071A1746c968e0798D642EDE491",
        abi: ABI.ERC20,
    },
];

export default tokenInfos;
