/**
 * Balances handler
 *
 * Provide an utility function for fetching user's balances,
 * as well as some default variables values.
 *
 * @module Balances/Data
 */

import Web3 from "web3";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import TokenInfo from "../../interfaces/TokenInfo";
import { ERC20ToFloat } from "../../utils/BN";

export const DEFAULT_BALANCE_VALUE = "...";

// 4 is the current maximum tokens in one pool. + 2 for ETH + WETH.
// Let's round to 10.
export const DEFAULT_BALANCES = new Array<string>(10).fill(DEFAULT_BALANCE_VALUE);

/**
 * Compute balance for one given token
 * @param tokenInfo : the token for which it
 * @param web3Context : web3 instance
 * @returns The balance of token for the connected account (string - divided by its decimals)
 */
const getTokenBalance = async (tokenInfo: TokenInfo, web3Context: Web3ReactContextInterface<Web3>): Promise<string> => {
    const { library: web3, account } = web3Context;
    if (web3 === undefined || account === undefined || account === null) {
        return DEFAULT_BALANCE_VALUE;
    }
    if (tokenInfo.symbol === "ETH") {
        return web3.utils.fromWei(await web3.eth.getBalance(account), "ether");
    }
    
    const contract = new web3.eth.Contract(tokenInfo.abi, tokenInfo.address);
    return ERC20ToFloat(await contract.methods.balanceOf(account).call(), await contract.methods.decimals().call());
};

/**
 * Compute balance for token that require an update
 * @param web3Context : web3 instance
 * @param tokenInfos  : array of tokens constants info
 * @returns
 */

export const computeBalances = (web3Context: Web3ReactContextInterface<Web3>, tokenInfos: TokenInfo[]) => {
    const balances: string[] = [...DEFAULT_BALANCES];
    const promises: Promise<void>[] = [];
    tokenInfos.forEach((tokenInfo, index) =>
        promises.push(
            getTokenBalance(tokenInfo, web3Context).then((balance) => {
                balances[index] = balance;
            })
        )
    );
    return new Promise<string[]>((resolve) => {
        Promise.all(promises).then(() => resolve(balances));
    });
};

export default computeBalances;
