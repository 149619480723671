import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            padding: theme.spacing(1),
            width: "100%",
            border: "none",
        },
        title: {
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: 14,
            textTransform: "uppercase",
        },
        balanceValue: {
            margin: 10,
            fontFamily: "Roboto",
        },
        paperBalance: {
            display: "flex",
            margin: 10,
            justifyContent: "space-between",
        },
        balanceButton: {
            margin: theme.spacing(1),
        },
    })
);
