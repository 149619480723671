import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "space-around",
            width: "67%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(5),
        },
        buttonsWindow: {
            display: "flex",
            justifyContent: "space-around",
            margin: theme.spacing(3),
        },
        actionButton: {},
        simpleDialogTitle: {
            textAlign: "center",
        },
        modalActions: {
            justifyContent: "center",
        },
        dialogContentText: {
            textAlign: "center",
        },
    })
);
