/**
 * Defines all types, data and hook relative to the Pool Infos Store
 * (see {@link Interfaces/Store}).
 * 
 * This Store manage the state of the selected investment pool,
 * so subscribers can retrieved the corresponding pool infos.
 * @module Store/PoolInfos
 */

import React, { createContext, useContext } from "react";

import DataStore, { IAction, IReducer } from "../interfaces/Store";

/**
 * NB: PoolInfos Store will only hold the index of
 * the currently used item in [[constants/strategyDataBase]].
 */
type Data = number;

type Payload = number;

type ActionType = "update";
type Action = IAction<ActionType, Payload>;

type Store = DataStore<Data, Action>;

/**
 * Possible actions:
 * - "update": replace the pool index.
 * 
 * @param prevState The current state
 * @param action The state update to apply
 * @returns the updated state
 */
export const reducer: IReducer<Data, Action> = (state, action) => {
    let newState = state;

    switch (action.type) {
        case "update":
        default:
            newState = action.payload;
            break;
    }

    return newState;
};

export const DEFAULT_DATA: Data = 25; //EURS pool

export const DEFAULT_STATE: Store = {
    data: DEFAULT_DATA,
    dispatch: (action: Action) => {
        throw "PoolInfos: Store.dispatch was not set.";
    },
};

/**
 * React context instance to access the data.
 * It is however recommended to use the [[usePoolInfos]] hook instead.
 */
export const context = createContext<Store>(DEFAULT_STATE);

/**
 * Abstract the context use with a hook-like function.
 * @returns The current state of the store.
 */
export const usePoolInfos = () => {
    const store = useContext(context);
    return store;
};

export default usePoolInfos;
